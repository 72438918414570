<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelf">
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll">
          <div style="color: #000001" v-if="zuduiList.length == 0">
            暂时没有团队...
          </div>
          <Spin fix v-show="spinFlag">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
          <div
            class="checkItem"
            v-for="(item, index) in zuduiList"
            :key="index"
          >
            <div
              class="jsicon"
              @click="delteam(item)"
              v-if="
                (item.team_type == '教师组' && item.leader.id == stateObj.id) ||
                (item.team_type != '教师组' && item.teacher1.id == stateObj.id)
              "
            >
              解散
            </div>
            
            <div class="item_hea">
              <div class="title">团队名称</div>
              <div class="detail">{{ item.team_nikename }}</div>
            </div>
            <div class="item_hea">
              <div class="title">团队类型</div>
              <div class="detail">{{ item.team_type }}</div>
            </div>
            <div class="item_hea" v-if="item.teacher1?.name">
              <div class="title">指导教师1</div>
              <div class="detail">
                {{ item.teacher1?.name }}-{{ item.teacher1?.phone }}
              </div>
            </div>
            <div class="item_hea" v-if="item.teacher2?.name">
              <div class="title">指导教师2</div>
              <div class="detail">
                {{ item.teacher2?.name }}-{{ item.teacher2?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队长</div>
              <div class="detail">
                {{ item.leader?.name }}-{{ item.leader?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员1</div>
              <div class="detail">
                {{ item.member1?.name }}-{{ item.member1?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员2</div>
              <div class="detail">
                {{ item.member2?.name }}-{{ item.member2?.phone }}
              </div>
            </div>
            
          </div>
        </div>

        <div class="btnBox">
          <div class="btn cacel" @click="proxy.$router.push('/')">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import {
  getSchoolContactsUser,
  getMyCreatTeam,
  delMyTeam,
  getMyTeam,
} from "@/api/ViewScreen/index";
let nowheader = ref("组队查询");
const CONFIG = window.CONFIG;
const spinFlag = ref(false);
let xxlxr = ref({}); //学校联系人信息对象
let xxlxyN = ref(""); //学校联系人展示字段
let stateObj = reactive({
  name: storage.session.get("name") ? storage.session.get("name") : "御网杯",
  user_type: storage.session.get("user_type")
    ? storage.session.get("user_type")
    : "",
  phone: storage.session.get("phone") ? storage.session.get("phone") : "",
  id: storage.session.get("id") ? storage.session.get("id") : "",
});
// 查询学校联络员
const getLLY = () => {
  getSchoolContactsUser({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        xxlxr.value = res.data;
        xxlxyN.value = `${res.data.name}-${res.data.phone}`;
      } else {
        return;
      }
    }
  );
};
let zuduiList = ref([]);
const getTeamList = () => {
  spinFlag.value = true;
  zuduiList.value = [];
  if (storage.session.get("user_type") == "教师") {
    getMyCreatTeam({ token: storage.session.get("sToken") }).then((res) => {
      spinFlag.value = false;
      if (res.code == 1) {
        zuduiList.value = res.data;
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else {
    getMyTeam({ token: storage.session.get("sToken") }).then((res) => {
      spinFlag.value = false;
      if (res.code == 1) {
        
          zuduiList.value = res.data
        
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  }
};
//解散团队
const delteam = debounce((obj) => {
  proxy.$Modal.confirm({
    title: "删除提示",
    content: "<p>您确定解散此团队吗?</p>",
    onOk: () => {
      delMyTeam({ token: storage.session.get("sToken"), team_id: obj.id }).then(
        (res) => {
          if (res.code == 1) {
            getTeamList();
          } else {
            proxy.$Message.error(res.msg);
          }
        }
      );
    },
    onCancel: () => {
      proxy.$Message.warning("取消解散");
    },
  });
}, 1000);
onMounted(() => {
  getLLY();
  getTeamList();
});
</script>
<style>
.mySelf .ivu-select-placeholder {
  text-align: left !important;
}
.mySelf .ivu-select-selected-value {
  text-align: left !important;
}

.mySelf .box .ivu-select {
  position: relative;
}
.mySelf .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}
.mySelf {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
      }
    }
    .detailBox {
      padding: 30px 20px 20px;
      border: 1px solid #eeeef0;
      flex: 1;
      position: relative;
      .checkItem {
        width: 100%;
        min-height: 80px;
        max-height: 300px;
        padding: 12px;
        border-bottom: 1px solid #eeeef0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .item_hea {
          display: flex;
          justify-content: space-between;

          height: 30px;
          line-height: 30px;
          margin-bottom: 10px;
          font-size: 16px;
          .title {
            width: 80px;
            color: #183139c9;
            font-weight: 600;
          }
          .detail {
            width: calc(100% - 90px);
            text-align: left;
            color: #1c0202c2;
          }
        }
        .jsicon {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          z-index: 1;
          width: 38px;
          height: 20px;
          background: #fb05053d;
          color: #f98e05a1;
          border-radius: 4px;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      height: 50px;
      align-items: center;
      .btn {
        cursor: pointer;
        width: auto;
        padding: 0 18px;
        margin: 0 12px;
        line-height: 28px;
        height: 28px;
        text-align: center;
      }
      .cacel {
        border: 1px solid #c0c0c0;
      }
      .ok {
        border: 1px solid #0080ff;
        color: #fff;
        background: #0080ff;
      }
      .ok2 {
        border: 1px solid #0080ff;
        padding: 0 8px;
        color: #0080ff;
      }
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    background: none;
  }
  .mySelf {
    display: block !important;
    width: 100%;
    padding: 16px;
    .right {
      width: 100%;
      height: 100%;
      .detailBox {
        .itemMyself {
          display: block;
          .title {
            text-align: left;
            color: #130b0b9c;
          }
          .box {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
