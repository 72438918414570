import { createApp } from 'vue'
//引入viewUi
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'


import '../public/iview.css'
import '../public/common.css'
import '../public/config.js?v=1'

import App from './App.vue'
import router from './router'
import store from './store'





createApp(App).use(store).use(router).use(ViewUIPlus).mount('#app')
