<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelf">
      <div class="left">
        <div class="header">个人中心</div>
        <div
          class="l_item"
          @click="nowheader = '个人信息'"
          :class="nowheader == '个人信息' ? 'l_avtive' : ''"
        >
          <div><img src="@/assets/zhao.png" alt="" /> 个人信息</div>
        </div>
        <div
          class="l_item"
          @click="nowheader = '密码修改'"
          :class="nowheader == '密码修改' ? 'l_avtive' : ''"
        >
          <div><img src="@/assets/password.png" alt="" /> 密码修改</div>
        </div>
      </div>
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="tips" v-if="nowheader == '个人信息'">
          <p v-html="CONFIG.tips"></p>
        </div>
        <div class="detailBox wwwscroll" v-if="nowheader == '个人信息'">
          <Spin fix v-show="spinFlag">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
          <div class="itemMyself">
            <div class="title"><span>*</span>真实姓名</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.name"
                placeholder="请输入真实姓名"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>用户身份</div>
            <div class="box">
              <Select v-model="myInfo.user_type" style="width: 100%" @on-change="changeUserType">
                <Option value="教师">教师</Option>
                <Option value="本科学生">本科学生</Option>
                <Option value="高职高专学生">高职高专学生</Option>
                <Option value="中职学生">中职学生</Option>
              </Select>
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>头像</div>
            <div class="box boxImg">
              <img v-if="myInfo.head_img != ''" :src="showImg" alt="" />
              <img v-else src="../assets/zhao.png" alt="" />
              <div class="uploadBox">
                <Upload :before-upload="handleUpload" action="/">
                  <Button icon="ios-cloud-upload-outline">请选择文件</Button>
                </Upload>
                <div>请上传证件照片(建议1寸)，此照片将用于准考证。</div>
              </div>
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>性别</div>
            <div
              class="box"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <RadioGroup v-model="myInfo.sex">
                <Radio :label="1">
                  <span>男</span>
                </Radio>
                <Radio :label="2">
                  <span>女</span>
                </Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>证件类型</div>
            <div
              class="box"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <RadioGroup v-model="myInfo.idcard_type">
                <Radio label="身份证">
                  <span>身份证</span>
                </Radio>
                <!-- <Radio label="护照">
                  <span>护照</span>
                </Radio> -->
              </RadioGroup>
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>证件号码</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.idcard"
                placeholder="请输入证件号码"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>民族</div>
            <div class="box">
              <Select v-model="myInfo.ethnicity" style="width: 100%" filterable>
                <Option
                  v-for="item in CONFIG.ChinaEthnicityData"
                  placeholder="请选择民族"
                  :value="item.name"
                  :key="item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>QQ号</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.qq"
                placeholder="请输入QQ号"
                style="width: 100%"
                maxlength="15"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>邮箱</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.email"
                placeholder="请输入邮箱"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>学校</div>
            <div class="box">
              
              <Select @on-change="changeSchool"  v-model="myInfo.school_id" style="width: 100%" filterable>
                <Option
                  v-for="item in sList" 
                  placeholder="请选择学校"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}-{{ item.type }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>院系</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.department"
                placeholder="请输入院系"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>专业</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.major"
                placeholder="请输入专业"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself" v-if="myInfo.user_type != '教师'">
            <div class="title"><span>*</span>入学年份</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.entrance_year"
                placeholder="请输入入学年份"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself" v-if="myInfo.user_type != '教师'">
            <div class="title"><span>*</span>年级</div>
            <div class="box">
              <Select v-model="myInfo.grade" style="width: 100%">
                <Option
                  v-for="item in CONFIG.nianjiList"
                  placeholder="请选择年级"
                  :value="item"
                  :key="item"
                  >{{ item }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="itemMyself" v-if="myInfo.user_type != '教师'">
            <div class="title"><span>*</span>学号</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.student_number"
                placeholder="请输入学号"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself" v-if="myInfo.user_type == '教师'">
            <div class="title"><span>*</span>职务</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.job_post"
                placeholder="请输入职务"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>上衣尺码</div>
            <div class="box">
              <Select v-model="myInfo.clothes_size" style="width: 100%">
                <Option
                  v-for="item in CONFIG.yifuchima"
                  placeholder="请选择上衣尺码"
                  :value="item"
                  :key="item"
                  >{{ item }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>擅长技能</div>
            <div
              class="box"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <CheckboxGroup v-model="goodat">
                <Checkbox
                  :label="item"
                  v-for="(item, index) in CONFIG.skill"
                  :key="index"
                ></Checkbox>
              </CheckboxGroup>
            </div>
          </div>

          <div class="itemMyself" v-if="myInfo.user_type == '教师'">
            <div class="title"><span>*</span>邮寄地址</div>
            <div class="box">
              <Input
                v-model.trim="myInfo.mail_address"
                placeholder="请输入邮寄地址"
                style="width: 100%"
                maxlength="50"
              />
            </div>
          </div>
          <div class="itemMyself" v-if="xxlxyN">
            <div class="title"><span>*</span>学校联络员</div>
            <div class="box">
              <Input
                placeholder="学校联络员"
                v-model="xxlxyN"
                style="width: 100%"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="btnBox" v-if="nowheader == '个人信息'">
          <div class="btn cacel" @click="proxy.$router.push('/')">返回</div>
          <div class="btn ok" @click="addOk">保存</div>
          <div
            class="btn ok2"
            @click="applylly"
            v-if="myInfo.user_type == '教师' && xxlxyN == ''"
          >
            申请为学校联系人
          </div>
        </div>
        <div class="detailBox wwwscroll" v-if="nowheader == '密码修改'">
          <div class="itemMyself">
            <div class="title"><span>*</span>原密码</div>
            <div class="box">
              <Input
                v-model.trim="editObj.old_password"
                placeholder="请输入原密码"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>新密码</div>
            <div class="box">
              <Input
                v-model.trim="editObj.new_password"
                placeholder="请输入新密码"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>确认新密码</div>
            <div class="box">
              <Input
                v-model.trim="editObj.new_password_repeat"
                placeholder="请输入新密码"
                style="width: 100%"
                maxlength="30"
              />
            </div>
          </div>
        </div>
        <div class="btnBox" v-if="nowheader == '密码修改'">
          <div class="btn cacel" @click="proxy.$router.push('/')">返回</div>
          <div class="btn ok" @click="editPasword">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import {
  getSchoolList,
  uploadHeadImg,
  getMyInfo,
  setMyInfo,
  changePassword,
  getSchoolContactsUser,
  applyToSchoolContacts,
} from "@/api/ViewScreen/index";
let nowheader = ref("个人信息");
const CONFIG = window.CONFIG;
const fileD = ref(); //选中图片文件
let showImg = ref(""); //头像展示
let myInfo = ref({}); //个人信息对象
let goodat = ref([]); //技能处理数组
let xxlxr = ref({}); //学校联系人信息对象
let xxlxyN = ref(""); //学校联系人展示字段

const school_id = ref('')


let spinFlag = ref(false);
const changeUserType =()=>{
  proxy.$forceUpdate()
}
//查询个人信息
const referMyInfo = () => {
  spinFlag.value = true;
  getMyInfo({ token: storage.session.get("sToken") }).then((res) => {
    spinFlag.value = false;
    if (res.code == 1) {
      myInfo.value =JSON.parse(JSON.stringify(res.data));
      school_id.value = res.data.school_id
      goodat.value = res.data.goodat;
      if (res.data.head_img) {
        showImg.value =
          process.env.VUE_APP_API_URL + res.data.head_img_abs_path;
        storage.session.set("name", myInfo.value.name);
        storage.session.set(
          "showImg",
          process.env.VUE_APP_API_URL + res.data.head_img_abs_path
        );
        storage.session.set("user_type", res.data.user_type);
        storage.session.set("phone", res.data.phone);
        storage.session.set("id", res.data.id);
      }
      if (myInfo.value.user_type == "教师") {
        getLLY();
      }
    } else {
      proxy.$Message.error(res.msg);
    }
    console.log(myInfo);
  });
};
// 查询学校联络员
const getLLY = () => {
  getSchoolContactsUser({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        xxlxr.value = res.data;
        xxlxyN.value = `${res.data.name}-${res.data.phone}`;
      } else {
        return;
      }
    }
  );
};
const applylly = () => {
  applyToSchoolContacts({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        getLLY();
        proxy.$Message.success(res.msg);
      } else {
        proxy.$Message.error(res.msg);
        return;
      }
    }
  );
};

//获取工具 学校列表
let sList = ref([]);
const getGetSchoolList = () => {
  getSchoolList({ token: storage.session.get("sToken") }).then((res) => {
    sList.value = [];
    if (res.code == 1) {
      sList.value = res.data;
      console.log(sList);
      proxy.$forceUpdate();
    } else {
      proxy.$Message.error(res.msg);
    }
  });
};
//上传头像
const handleUpload = (file) => {
  if (
    file.type == "image/jpeg" ||
    file.type == "image/jpg" ||
    file.type == "image/png"
  ) {
    fileD.value = file;
    uploadHeadImg({
      token: storage.session.get("sToken"),
      image: fileD.value,
    }).then((res) => {
      if (res.code == 1) {
        proxy.$Message.success(res.msg);
        myInfo.value.head_img = res.data.path;
        proxy.$forceUpdate();

        showImg.value = process.env.VUE_APP_API_URL + res.data.abs_path;
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else {
    proxy.$Message.warning("仅支持上传jpg/png类型的图片");
  }
};
//保存个人信息
const addOk = debounce(() => {
  
 
  if (myInfo.value.name == "") {
    proxy.$Message.warning("请输入姓名");
    return;
  }
  if (myInfo.value.sex == "") {
    proxy.$Message.warning("请选择性别");
    return;
  }
  if (myInfo.value.head_img == "") {
    proxy.$Message.warning("请上传头像");
    return;
  }
  if (myInfo.value.idcard_type == "") {
    proxy.$Message.warning("请选择证件类型");
    return;
  }
  if (myInfo.value.idcard == "") {
    proxy.$Message.warning("请输入证件号码");
    return;
  }
  if (myInfo.value.ethnicity == "") {
    proxy.$Message.warning("请选择民族");
    return;
  }
  if (myInfo.value.qq == "") {
    proxy.$Message.warning("请输入QQ号");
    return;
  }
  if (myInfo.value.school_id == "") {
    proxy.$Message.warning("请选择学校");
    return;
  }
  if (myInfo.value.department == "") {
    proxy.$Message.warning("请输入院系");
    return;
  }
  if (myInfo.value.major == "") {
    proxy.$Message.warning("请输入专业");
    return;
  }
  if (myInfo.value.entrance_year == "" && myInfo.value.user_type !='教师' && myInfo.value.user_type !='') {
    proxy.$Message.warning("请输入入学年份");
    return;
  }
  if (myInfo.value.grade == "" && myInfo.value.user_type !='教师' && myInfo.value.user_type !='') {
    proxy.$Message.warning("请选择年级");
    return;
  }
  if (myInfo.value.student_number == "" && myInfo.value.user_type !='教师' && myInfo.value.user_type !='') {
    proxy.$Message.warning("请输入学号");
    return;
  }
  if (myInfo.value.job_post == "" && myInfo.value.user_type =='教师') {
    proxy.$Message.warning("请输入职务");
    return;
  }
  if (myInfo.value.clothes_size == "") {
    proxy.$Message.warning("请选择上衣尺码");
    return;
  }
  if (goodat.value.length < 1) {
    proxy.$Message.warning("请选择擅长技能");
    return;
  }
  if (myInfo.value.mail_address == "" && myInfo.value.user_type =='教师') {
    proxy.$Message.warning("请输入邮寄地址");
    return;
  }
  myInfo.value.goodat = goodat.value;
  
  myInfo.value.token = storage.session.get("sToken");
  setMyInfo(myInfo.value).then((res) => {
    if (res.code == 1) {
      referMyInfo();
      proxy.$Message.success(res.msg);
    } else {
      proxy.$Message.error(res.msg);
    }
  });
}, 500);
//修改密码对象声明
const editObj = reactive({
  old_password: "",
  new_password: "",
  new_password_repeat: "",
  token: storage.session.get("sToken"),
});
//修改密码
const editPasword = debounce(() => {
  if (editObj.old_password == "") {
    proxy.$Message.warning("请输入原密码");
    return;
  }
  if (editObj.new_password == "") {
    proxy.$Message.warning("请输入新密码");
    return;
  }
  if (editObj.new_password_repeat == "") {
    proxy.$Message.warning("请输入确认新密码");
    return;
  }
  if (editObj.new_password != editObj.new_password_repeat) {
    proxy.$Message.warning("请确认新密码两次输入一致");
    return;
  }
  changePassword(editObj).then((res) => {
    if (res.code == 1) {
      editObj.old_password = "";
      editObj.new_password = "";
      editObj.new_password_repeat = "";

      proxy.$Message.success(res.msg);
    } else {
      proxy.$Message.error(res.msg);
    }
  });
}, 500);
const changeSchool = res=>{
  console.log(myInfo.value.school_id)
}
onMounted(() => {
  referMyInfo();
  getGetSchoolList();
});
</script>

<style>
.mySelf .ivu-select-placeholder {
  text-align: left !important;
}
.mySelf .ivu-select-selected-value {
  text-align: left !important;
}
.mySelf .ivu-select-dropdown {
  background: #fff;
}
.mySelf .ivu-select-item {
  color: #000;
}
.register1 .item .ivu-select-item:hover {
  background: #fff;
}
.mySelf .box .ivu-select {
  position: relative;
}
.mySelf .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>

<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}
.mySelf {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  .left {
    width: 260px;
    height: 100%;
    .header {
      background: #ececec;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: #565656;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
    }
    .l_item {
      height: 32px;
      line-height: 32px;
      width: 100%;
      cursor: pointer;
      margin: 10px 0;
      div {
        padding: 0 15px;
        text-align: center;
        color: #878787;
        border-bottom: 1px solid #ededed;
        width: 200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
    .l_avtive {
      border-left: 1px solid #0080ff;
    }
  }
  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;
      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
      }
    }
    .tips {
      padding: 10px;
      background: #eeeef0;
      color: #fb6233;
      margin: 15px 0;
      text-align: left;
    }
    .detailBox {
      padding: 30px 20px 20px;
      border: 1px solid #eeeef0;
      flex: 1;
      position: relative;
      .itemMyself {
        width: 100%;
        display: flex;
        justify-content: space-between;
        min-height: 40px;
        line-height: 40px;
        align-items: center;
        margin-bottom: 10px;
        .title {
          width: 100px;
          text-align: right;
          span {
            color: red;
          }
        }
        .box {
          width: 480px;
          height: 100%;
        }
        .boxImg {
          display: flex;
          justify-content: space-between;
          img {
            width: 100px;
            height: 140px;
          }
          .uploadBox {
            width: 320px;
            display: flex;
            justify-content: space-between;
            color: red;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      height: 50px;
      align-items: center;
      .btn {
        cursor: pointer;
        width: auto;
        padding: 0 18px;
        margin: 0 12px;
        line-height: 28px;
        height: 28px;
        text-align: center;
      }
      .cacel {
        border: 1px solid #c0c0c0;
      }
      .ok {
        border: 1px solid #0080ff;
        color: #fff;
        background: #0080ff;
      }
      .ok2 {
        border: 1px solid #0080ff;
        padding: 0 8px;
        color: #0080ff;
      }
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    background: none;
  }
  .mySelf {
    display: block !important;
    width: 100%;
    padding: 16px;
    .left {
      height: auto;
      display: flex;
      width: 100%;
      .header {
        display: none;
      }
      .l_avtive {
        border-left: 1px solid #fff;
        border-bottom: 1px solid #0080ff;
      }
      .l_item {
        width: 50%;
        div {
          width: 100%;
        }
      }
    }
    .right {
      width: 100%;
      height: calc(100% - 52px);
      .R_header {
        display: none;
      }
      .tips {
        padding: 0 10px;
        margin: 0 0 10px;
      }
      .detailBox {
        .itemMyself {
          display: block;
          .title {
            text-align: left;
            color: #130b0b9c;
          }
          .box {
            width: 100%;
          }
          .uploadBox {
            width: 50% !important;
          }
        }
      }
    }
  }
}
</style>
