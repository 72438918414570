<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelf">
      <div class="left">
        <div class="header">个人中心</div>
        <div
          class="l_item"
          @click="nowheader = '教师组队'"
          :class="nowheader == '教师组队' ? 'l_avtive' : ''"
        >
          <div>教师组</div>
        </div>

        <div
          class="l_item"
          @click="nowheader = '学生组队'"
          :class="nowheader == '学生组队' ? 'l_avtive' : ''"
        >
          <div>学生组</div>
        </div>
        <!-- <div
          class="l_item"
          @click="nowheader = '应急响应组队'"
          :class="nowheader == '应急响应组队' ? 'l_avtive' : ''"
        >
          <div>应急响应组</div>
        </div> -->
      </div>
      <div class="right">
        <Spin fix v-show="spinFlag">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll" v-if="nowheader == '教师组队'">
          
          <div class="itemMyself">
            <div class="title">学校联络员</div>
            <div class="box">
              <Input
                placeholder="学校联络员"
                v-model="xxlxyN"
                style="width: 100%"
                readonly
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>团队昵称</div>
            <div class="box">
              <Input
                v-model.trim="jszdObj.team_nikename"
                placeholder="请输入团队昵称"
                style="width: 100%"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title" style="width: 100%"><span>*</span>队长姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="stateObj.name"
                placeholder="姓名"
                readonly
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title" style="width: 100%">
              <span>*</span>队长手机号
            </div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="stateObj.phone"
                placeholder="手机号"
                readonly
                maxlength="11"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员1姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="jszdObj.member_1_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员1手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                placeholder="手机号"
                maxlength="11"
                v-model="jszdObj.member_1_phone"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员2姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="jszdObj.member_2_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员2手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                placeholder="手机号"
                maxlength="11"
                v-model="jszdObj.member_2_phone"
              />
            </div>
          </div>
        </div>

        <div class="detailBox wwwscroll" v-if="nowheader == '学生组队'">
          
          <div class="itemMyself">
            <div class="title">学校联络员</div>
            <div class="box">
              <Input
                placeholder="学校联络员"
                v-model="xxlxyN"
                style="width: 100%"
                readonly
              />
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>学生组别</div>
            <div
              class="box"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <RadioGroup
                v-model="xszdObj.team_type"
                v-if="studentType.length > 0"
              >
                <Radio :label="item" v-for="item in studentType">
                  <span>{{ item }}</span>
                </Radio>

                <!-- <Radio :label="'本科组'" >     
                  <span>本科组</span>
                </Radio>
                <Radio :label="'高职组'">
                  <span>高职组</span>
                </Radio>
                <Radio :label="'中职组'">
                  <span>中职组</span>
                </Radio> -->
              </RadioGroup>
              <span style="color: red" v-else
                >个人赛报名人数不足，暂无法组队。</span
              >
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>团队昵称</div>
            <div class="box">
              <Input
                v-model.trim="xszdObj.team_nikename"
                placeholder="请输入团队昵称"
                style="width: 100%"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title" style="width: 100%">
              <span>*</span>指导教师1姓名
            </div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="stateObj.name"
                placeholder="姓名"
                readonly
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title" style="width: 100%">
              <span>*</span>指导教师1手机号
            </div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="stateObj.phone"
                placeholder="手机号"
                readonly
                maxlength="11"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title" style="width: 100%">
              指导教师2姓名
            </div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.teacher2_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title" style="width: 100%">
              指导教师2手机号
            </div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.teacher2_phone"
                placeholder="手机号"
                maxlength="11"
              />
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>队长姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.leader_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队长手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.leader_phone"
                placeholder="手机号"
                maxlength="11"
              />
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>队员1姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.member_1_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员1手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                placeholder="手机号"
                maxlength="11"
                v-model="xszdObj.member_1_phone"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员2姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.member_2_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员2手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                placeholder="手机号"
                maxlength="11"
                v-model="xszdObj.member_2_phone"
              />
            </div>
          </div>
        </div>
        <div class="detailBox wwwscroll" v-if="nowheader == '应急响应组队'">
          
          <div class="itemMyself">
            <div class="title">学校联络员</div>
            <div class="box">
              <Input
                placeholder="学校联络员"
                v-model="xxlxyN"
                style="width: 100%"
                readonly
              />
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>团队昵称</div>
            <div class="box">
              <Input
                v-model.trim="xszdObj.team_nikename"
                placeholder="请输入团队昵称"
                style="width: 100%"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队长姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.leader_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队长手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.leader_phone"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>

          <div class="itemMyself">
            <div class="title"><span>*</span>队员1姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.member_1_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员1手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                placeholder="手机号"
                maxlength="11"
                v-model="xszdObj.member_1_phone"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员2姓名</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xszdObj.member_2_name"
                placeholder="姓名"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemMyself">
            <div class="title"><span>*</span>队员2手机号</div>
            <div class="box">
              <Input
                style="width: 100%"
                placeholder="手机号"
                maxlength="11"
                v-model="xszdObj.member_2_phone"
              />
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div class="btn cacel" @click="proxy.$router.push('/')">返回</div>
          <div class="btn ok" @click="addOk(1)" v-if="nowheader == '教师组队'">
            组队
          </div>
          <div class="btn ok" @click="addOk(2)" v-if="nowheader == '学生组队'">
            组队
          </div>
          <!-- <div
            class="btn ok"
            @click="addOk(3)"
            v-if="nowheader == '应急响应组队'"
          >
            组队
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import {
  getSchoolContactsUser,
  createTeacherTeam,
  createStudentTeam,
  getTeamType,
} from "@/api/ViewScreen/index";
let nowheader = ref("教师组队");
const CONFIG = window.CONFIG;
let myInfo = reactive({}); //个人信息对象
let stateObj = reactive({
  name: storage.session.get("name") ? storage.session.get("name") : "御网杯",
  user_type: storage.session.get("user_type")
    ? storage.session.get("user_type")
    : "",
  phone: storage.session.get("phone") ? storage.session.get("phone") : "",
});
let xxlxr = ref({}); //学校联系人信息对象
let xxlxyN = ref(""); //学校联系人展示字段
let spinFlag = ref(false);
let jszdObj = ref({
  //教师组队对象
  team_nikename: "",
  member_1_phone: "",
  member_1_name: "",
  member_2_phone: "",
  member_2_name: "",
});
let xszdObj = ref({
  //学生对组队对象
  team_type: "",
  team_nikename: "",
  teacher2_phone: "",
  teacher2_name: "",
  leader_phone: "",
  leader_name: "",
  member_1_phone: "",
  member_1_name: "",
  member_2_phone: "",
  member_2_name: "",
});
// 查询学校联络员
const getLLY = () => {
  getSchoolContactsUser({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        xxlxr.value = res.data;
        xxlxyN.value = `${res.data.name}-${res.data.phone}`;
      } else {
        return;
      }
    }
  );
};
const initData = () => {
  jszdObj.value = {
    //教师组队对象
    team_nikename: "",
    member_1_phone: "",
    member_1_name: "",
    member_2_phone: "",
    member_2_name: "",
  };
  xszdObj.value = {
    //学生对组队对象
    team_type: "",
    team_nikename: "",
    teacher2_phone: "",
    teacher2_name: "",
    leader_phone: "",
    leader_name: "",
    member_1_phone: "",
    member_1_name: "",
    member_2_phone: "",
    member_2_name: "",
  };
};

//组队
const addOk = debounce((val) => {
  myInfo.token = storage.session.get("sToken");
  if (val == 1) {
    //教师组队逻辑
    if (jszdObj.value.team_nikename == "") {
      proxy.$Message.warning("请填写团队昵称");
      return;
    }
    if (jszdObj.value.member_1_phone == "") {
      proxy.$Message.warning("请填写队员1手机号");
      return;
    }
    if (jszdObj.value.member_1_name == "") {
      proxy.$Message.warning("请填写队员1姓名");
      return;
    }
    if (jszdObj.value.member_2_phone == "") {
      proxy.$Message.warning("请填写队员2手机号");
      return;
    }
    if (jszdObj.value.member_2_name == "") {
      proxy.$Message.warning("请填写队员2姓名");
      return;
    }
    jszdObj.value.token = storage.session.get("sToken");
    spinFlag.value = true;
    createTeacherTeam(jszdObj.value).then((res) => {
      spinFlag.value = false;
      if (res.code == 1) {
        proxy.$Message.success("组队成功");
        initData();
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else if (val == 2) {
    // 学生组队逻辑
    if (xszdObj.value.team_type == "") {
      proxy.$Message.warning("请选择学生组别");
      return;
    }
    if (xszdObj.value.team_nikename == "") {
      proxy.$Message.warning("请填写团队昵称");
      return;
    }
    if (xszdObj.value.leader_phone == "") {
      proxy.$Message.warning("请填写队长手机号");
      return;
    }
    if (xszdObj.value.leader_name == "") {
      proxy.$Message.warning("请填写队长姓名");
      return;
    }

    
    if (xszdObj.value.member_1_phone == "") {
      proxy.$Message.warning("请填写队员1手机号");
      return;
    }
    if (xszdObj.value.member_1_name == "") {
      proxy.$Message.warning("请填写队员1姓名");
      return;
    }
    if (xszdObj.value.member_2_phone == "") {
      proxy.$Message.warning("请填写队员2手机号");
      return;
    }
    if (xszdObj.value.member_2_name == "") {
      proxy.$Message.warning("请填写队员2姓名");
      return;
    }
    xszdObj.value.token = storage.session.get("sToken");
    spinFlag.value = true;
    createStudentTeam(xszdObj.value).then((res) => {
      spinFlag.value = false;
      if (res.code == 1) {
        proxy.$Message.success("组队成功");
        initData();
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else {
    //应急响应赛组队逻辑
  }
}, 500);
let studentType = ref([]);
onMounted(() => {
  getTeamType({ token: storage.session.get("sToken") }).then((res) => {
    if (res.code == 1) {
      studentType.value = res.data;
    }
  });

  getLLY();
});
</script>
<style>
.mySelf .ivu-select-placeholder {
  text-align: left !important;
}
.mySelf .ivu-select-selected-value {
  text-align: left !important;
}

.mySelf .box .ivu-select {
  position: relative;
}
.mySelf .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}
.mySelf {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  .left {
    width: 260px;
    height: 100%;
    .header {
      background: #ececec;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      color: #565656;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-bottom: 1px solid #f6f6f6;
    }
    .l_item {
      height: 32px;
      line-height: 32px;
      width: 100%;
      cursor: pointer;
      margin: 10px 0;
      div {
        padding: 0 15px;
        text-align: center;
        color: #878787;
        border-bottom: 1px solid #ededed;
        width: 200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
    .l_avtive {
      border-left: 1px solid #0080ff;
    }
  }
  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
      }
    }
    .tips {
      padding: 10px;
      background: #eeeef0;
      color: #fb6233;
      margin: 15px 0;
      text-align: left;
    }
    .detailBox {
      padding: 30px 20px 20px;
      border: 1px solid #eeeef0;
      flex: 1;
      position: relative;
      .itemMyself {
        width: 100%;
        display: flex;
        justify-content: space-between;
        min-height: 40px;
        line-height: 40px;
        align-items: center;
        margin-bottom: 10px;
        .title {
          width: 100px;
          text-align: right;
          span {
            color: red;
          }
        }
        .box {
          width: 480px;
          height: 100%;
        }
        .boxImg {
          display: flex;
          justify-content: space-between;
          img {
            width: 100px;
            height: 140px;
          }
          .uploadBox {
            width: 320px;
            display: flex;
            justify-content: space-between;
            color: red;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      height: 50px;
      align-items: center;
      .btn {
        cursor: pointer;
        width: auto;
        padding: 0 18px;
        margin: 0 12px;
        line-height: 28px;
        height: 28px;
        text-align: center;
      }
      .cacel {
        border: 1px solid #c0c0c0;
      }
      .ok {
        border: 1px solid #0080ff;
        color: #fff;
        background: #0080ff;
      }
      .ok2 {
        border: 1px solid #0080ff;
        padding: 0 8px;
        color: #0080ff;
      }
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    background: none;
  }
  .mySelf {
    display: block !important;
    width: 100%;
    padding: 16px;
    .left {
      width: 100%;
      height: auto;
      display: flex;
      .header {
        display: none;
      }
      .l_avtive {
        border-left: 1px solid #fff;
        border-bottom: 1px solid #0080ff;
      }
      .l_item {
        width: 50%;
        div {
          width: 100%;
        }
      }
    }
    .right {
      width: 100%;
      height: calc(100% - 52px);
      .R_header {
        display: none;
      }

      .detailBox {
        .itemMyself {
          display: block;
          .title {
            text-align: left;
            color: #130b0b9c;
          }
          .box {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
