<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelfLogin">
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll">
          <div class="form">
            <div class="item">
              <div class="title"><img src="@/assets/zhao.png" alt="" /></div>
              <div class="box">
                <Input
                  style="width: 100%"
                  v-model="wjmmObj.phone"
                  placeholder="请输入手机号"
                />
              </div>
            </div>
            <div class="item">
              <div class="title">
                <img src="@/assets/password.png" alt="" />
              </div>
              <div class="box">
                <Input
                  style="width: 100%"
                  v-model="wjmmObj.password"
                  placeholder="请设置密码"
                />
              </div>
            </div>
            <div class="itemB">
              <div class="title">
                <Input
                  style="width: 100%"
                  v-model="wjmmObj.yzm"
                  placeholder="图形验证码"
                />
              </div>
              <div class="box" @click="getWJMMCaptcha">
                <img :src="WJMM" alt="" />
              </div>
            </div>
            <div class="itemB">
              <div class="title">
                <Input
                  style="width: 100%"
                  v-model="wjmmObj.yzm2"
                  placeholder="验证码"
                />
              </div>
              <div class="box" @click="getwjyzm">
                <div class="btn">{{ wjyzmNewTitle }}</div>
              </div>
            </div>
          </div>
          <div class="submit" style="margin-top: 50px" @click="wjmmok">
            点击更改
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import {
  getCaptcha,
  doChangePwd,
  sendChangePwdCode,
} from "@/api/ViewScreen/index";
let nowheader = ref("找回密码");
const CONFIG = window.CONFIG;

//倒计时
let wjyzmNewTitle = ref("获取短信验证码");
let wjyzmNewFlag = ref(false);
let wjtimeNew = ref(60);
let wjtimer = "";
const wjsetIntervalfn = () => {
  if (wjyzmNewFlag.value) {
    return;
  } else {
    wjyzmNewFlag.value = true;
    wjtimer = setInterval(() => {
      if (wjyzmNewFlag.value == true) {
        // console.log("触发");
        wjyzmNewTitle.value = wjtimeNew.value + " s";
        wjtimeNew.value--;
        callback(wjtimeNew);
      }
    }, 1000);
    const callback = (wjtimeNew) => {
      // console.log(timeNew.value);
      if (wjtimeNew.value < 0) {
        wjtimeNew.value = 60;
        wjyzmNewFlag.value = false;
        wjyzmNewTitle.value = "获取验证码";
        clearInterval(wjtimer);
      }
    };
  }
};
let wjmmObj = reactive({
  phone: "", //手机号
  password: "", //密码
  yzm: "",
  yzm2: "",
});
const wjregisterInit = () => {
  // eslint-disable-next-line no-const-assign
  wjmmObj = reactive({
    phone: "", //手机号
    password: "", //密码
    yzm: "",
    yzm2: "",
  });
  wjtimeNew.value = 60;
  wjyzmNewFlag.value = false;
  wjyzmNewTitle.value = "获取验证码";
  clearInterval(wjtimer);
};
let WJMM_SIGN = ref(""); //图片验证码随机签名
let WJMM = ref("");
//获取验证码
const getWJMMCaptcha = debounce(() => {
  wjmmObj.yzm=''
  WJMM_SIGN.value = "";
  WJMM_SIGN.value = Math.ceil(Math.random() * 10000000000 + 1);
  // 请求验证码后放到这里
  WJMM.value = getCaptcha({ scene: "changePwd", sign: WJMM_SIGN.value });
}, 100);

//忘记密码 获取手机验证码
const getwjyzm = debounce(() => {
  if (wjyzmNewFlag.value) {
    return;
  }
  if (wjmmObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (wjmmObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  sendChangePwdCode({
    phone: wjmmObj.phone,
    sign: WJMM_SIGN.value,
    captcha: wjmmObj.yzm,
  }).then((res) => {
    console.log(res);
    if (res.code == 1) {
      wjsetIntervalfn();
    } else {
      wjmmObj.yzm = ""
      getWJMMCaptcha()
      proxy.$Message.error(res.msg);
    }
  });
}, 1000);

//忘记密码执行函数
const wjmmok = debounce(() => {
  if (wjmmObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (wjmmObj.password == "" || wjmmObj.password.length < 6) {
    proxy.$Message.warning("请设置密码且密码不能少于6位");
    return;
  }
  if (wjmmObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  if (wjmmObj.yzm2 == "") {
    proxy.$Message.warning("请输入手机验证码");
    return;
  }
  doChangePwd({
    phone: wjmmObj.phone,
    password: wjmmObj.password,
    code: wjmmObj.yzm2,
  }).then((res) => {
    if (res.code == 1) {
      proxy.$Message.success(res.msg);
      wjregisterInit()
    } else {
      proxy.$Message.error(res.msg);
    }
  });
}, 500);

onMounted(() => {
  wjregisterInit();
  getWJMMCaptcha();
});
</script>
<style>
.mySelfLogin .ivu-input {
  height: 40px !important;
}
.mySelfLogin .item .ivu-input {
  color: #badde0;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid #565867;
}
.mySelfLogin .itemB .ivu-input {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #44f2ff;
  text-align: center;
  color: #badde0;
}

.mySelfLogin .ivu-select-selected-value {
  text-align: left !important;
}

.mySelfLogin .box .ivu-select {
  position: relative;
}
.mySelfLogin .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}
.mySelfLogin {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0);
  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
        color: #fff;
      }
    }
    .detailBox {
      padding: 30px 20px 20px;
      flex: 1;
      position: relative;
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    // background: none;
  }
  .mySelfLogin {
    display: block !important;
    width: 100%;
    padding: 16px;
    .right {
      width: 100%;
      height: 100%;
      .detailBox {
        .form {
          width: 310px;
          margin: 30px auto;
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .title {
              width: 20px;
              display: flex;
              align-items: center;

              img {
                width: 20px;
              }
            }
            .box {
              width: 275px;
            }
          }
          .itemB {
            padding-left: 35px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .title {
              width: 140px;
            }
            .box {
              width: 120px;
              cursor: pointer;
              .btn {
                width: 120px;
                height: 40px;
                line-height: 40px;
                background: #44f2ff;
                border: 1px solid #44f2ff;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 500;
                color: #010b17;
                text-align: center;
              }
              img {
                width: 120px;
                height: 40px;
              }
            }
          }
        }
        .submit {
          margin: 0 auto;
          width: 180px;
          height: 35px;
          background: #0556ab;
          border-radius: 17px;
          text-align: center;
          line-height: 35px;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
        }
        .fotter {
          margin-top: 15px;
          text-align: right;
          color: #badde0;
          // padding-right: 60px;
          span {
            color: #3be4ff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
