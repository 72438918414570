
import axios from "axios";
import { storage } from "llm-web-tool";
let baseURL = process.env.VUE_APP_API_URL;
// let baseURL = '/dev-api';
// 导出API前缀
export const BaseURL = baseURL; //线上模式 在主index页面
import {
	Modal
} from 'view-ui-plus'
// 请求拦截器
const beforeSend = options => {

	// console.log(options)
	// 设置默认请求方法
	options.url = options.url + '?' //给地址后面加？用来拼接参数
	// console.log(options.url)
	if (typeof options.headers == "undefined" && options.param_type) {
		options.headers = {
			// 'content-type': 'application/x-www-form-urlencoded'
			"Content-Type": "application/json;charset=UTF-8",

		};
	} else {
		options.headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
			// "Content-Type": "application/json;charset=UTF-8",
			// 'Token':sToken

		};
	}
	if (typeof options.method == "undefined") {
		options.method = "POST";
	}

	// 处理API的URL前缀
	Object.keys(options).forEach(key => {
		options[key] = options[key];
		// 判断key 值里有url 并有http协议就自动加上请求前缀（服务器的域名)
		if (key == "url" && options[key].indexOf("http") !== 0) {
			options[key] = baseURL + options[key];
		}
	});

	// 处理授权问题
	if (typeof options.data == "undefined") options.data = {};
	// 如果是GET方式，将data载入params 
	if (options.method.toLocaleLowerCase() == "get") {
		options.params = options.data;
	}
	if (!options.param_type && !options.param_typearr) {

		if (options.data) {
			let fd = new FormData();
			Object.keys(options.data).forEach(key => {
				console.log(key, options.data[key])
				fd.append(key, options.data[key]);
			});
			options.data = fd;
		}
	} else if (options.param_typearr) { //穿文件数组
		if (options.data) {
			let fd = new FormData();
			if (!options.canshu) {
				let key = Object.keys(options.data) //获取到后台接受的key名

				for (let i = 0; i < options.data[key].length; i++) {
					fd.append(key, options.data[key][i]);
				}
			} else {
				let key = Object.keys(options.data) //获取到后台接受的key名
				console.log(key)
				for (let i = 0; i < key.length; i++) {
					console.log(key[i], 71)
					if (key[i] == 'goodat') {

						for (let m = 0; m < options.data[key[i]].length; m++) {
							// console.log(options.data[key[i]][m])
							fd.append(`${key[i]}[]`, options.data[key[i]][m]);
						}
					}
				}
				Object.keys(options.data).forEach(key => {
					if (key != 'goodat') {
						fd.append(key, options.data[key]);
					}
				});
			}
			options.data = fd;
		}
	} else {
		// json对象
		options.data = JSON.stringify(options.data)
	}
	return options;
};

// 响应拦截器
const befroeResponse = response => {
	console.log(response)
	if (response.data.msg == '请先登录') {
		Modal.warning({
			title: '登录超时',
			content: '请重新登录',
			onOk: () => {
				storage.session.remove("name");
				storage.session.remove("showImg");
				storage.session.remove("user_type");
				storage.session.remove("phone");
				storage.session.remove("id");
				storage.session.remove("sToken");

				window.location.href = '/'
			},
		});
		return
	}
	return response.data;
};

// 错误处理器
const onRequestError = e => {
	return e;
};

// 导出请求方法
export const XHR = (options = {}) => {
	return new Promise((resolve, reject) => {
		axios(beforeSend(options))
			.then(r => {
				resolve(befroeResponse(r));
			})
			.catch(e => {
				reject(onRequestError(e));
			});
	});
};
