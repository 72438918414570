window.CONFIG = {
    fileTitle:'第八届御网杯信息安全大赛',
    DSJJ: {
        title: '',
        mark: "为深入贯彻落实习近平总书记网络强国战略思想，增强师生网络安全意识，提升高校网络空间安全防护能力，锻炼网信领域人才专业能力，省教育厅定于2024年7月至11月举办第八届御网杯信息安全大赛。"
    },
    BMLC: [
        {
            step: 1,
            title: '注册',
            mark: '1.注册报名时间：2024年7月26日8:00至9月20日18:00。<br>2.注册时要求实名注册，省内外具有正式学籍的全日制在校学生均可报名参赛。'
        },
        {
            step: 2,
            title: '个人赛报名',
            mark: '1.注册成功之后，登录系统并在个人中心按照真实情况完善个人信息，即表示个人赛报名成功。<br>2.教师可以查看本校报名成功的学生名单。'
        },
        {
            step: 3,
            title: '团体赛组队',
            mark: '1.所有团队的组建工作都由教师进行。<br/>2.各参赛单位需指定 1名联络员，负责报名及竞赛期间的协调和联络工作。<br/>3.团队组建完成后，由学校联络员下载报名表，并由学校、学院或教务处审核信息盖章，然后扫描上传。'
        }, 
        // {
        //     step: 4,
        //     title: '比赛时间',
        //     mark: '1.未参加线上个人挑战赛的学生，无法注册报名<br>2.学生注册以线上个人赛使用的手机号注册'
        // }, 
        {
            step: 5,
            title: '团队公示',
            mark: '1.报名组队结束之后，将在大赛官网公示团体赛团队名单。'
        }
    ],
    BMLCPhone:[
        {
            G1:'个人赛',
            N1:'1.注册报名时间：2024年7月26日8:00至9月20日18:00。',
            N2:'2.注册时要求实名注册，省内外具有正式学籍的全日制在校学生均可报名参赛。',
            N3:'3.注册成功之后，登录系统并在个人中心按照真实情况完善个人信息，即表示个人赛报名成功。',
            N4:'4.教师可以查看本校报名成功的学生名单。',
        },
        {
            G1:'团体赛',
            N1:'1.报名组队时间：2024年7月26日8:00至9月20日18:00。',
            N2:'2.教师和学生都需要注册并完善个人信息。',
            N3:'3.所有团队的组建工作都由教师进行。',
            N4:'4.报名组队步骤如下：',
            N41:'&nbsp;&nbsp;&nbsp;&nbsp;①注册并完善信息，教师和学生按照系统提示和要求完成注册并完善个人信息。',
            N42:'&nbsp;&nbsp;&nbsp;&nbsp;②确定学校联络员，参加往届大赛的学校都已经设有联络员，需要变更联络员请联系赛事组委会。',
            N43:'&nbsp;&nbsp;&nbsp;&nbsp;③组建团队，只有教师用户能组建团队，可根据学校实际情况组建教师组或学生组团队。',
            N44:'&nbsp;&nbsp;&nbsp;&nbsp;④提交资料，团队组建完成后，由学校联络员下载报名表，并由学校、学院或教务处审核信息盖章，然后扫描上传。',
            N45:'&nbsp;&nbsp;&nbsp;&nbsp;组队完成，等待公示..',
        },
    ],
    SCAP: [
        // {
        //     name: '学生团体赛',
        //     mark: '高校网络安全技术部门及计算机专业相关院系教师组队参加，主要是通过模拟高校信息系统及应用的安全漏洞环境，提升信息系统运维人员和相关专业教师的实战能力，不允许跨校组队、师生混合组队。'
        // },
        // {
        //     name: '教师团体赛',
        //     mark: '高校网络安全技术部门及计算机专业相关院系教师组队参加，主要是通过模拟高校信息系统及应用的安全漏洞环境，提升信息系统运维人员和相关专业教师的实战能力，不允许跨校组队、师生混合组队。'
        // },
        // {
        //     name: '应急响应赛',
        //     mark: '高校网络安全技术部门及计算机专业相关院系教师组队参加，主要是通过模拟高校信息系统及应用的安全漏洞环境，提升信息系统运维人员和相关专业教师的实战能力，不允许跨校组队、师生混合组队。'
        // },
    ],
    JXSZ: [
        '一等奖15%', '二等奖25%', '三等奖30%'
    ],
    XSJXSZ:[
        '一等奖5%', '二等奖8%', '三等奖12%'
    ],
    SXZZ: [
        {
            name: '主办单位',
            chid: [
                '河南省教育厅'
            ]
        },
        {
            name: '承办单位',
            chid: [
                '河南省高等学校计算机教育研究会',
                '河南省新一代信息技术职业教育集团'
            ]
        },
        {
            name: '协办单位',
            chid: [
                '河南经贸职业学院',
                '河南省网络空间安全行业产教融合共同体',
                '北京理工大学信息系统及安全对抗实验中心',
            ]
        }

    ],
    LXWM: [
        {
            name: '省教育厅科信处：',
            mark: '郭晓枫&nbsp;&nbsp;&nbsp;&nbsp;0371-69691761'
        },
        {
            name: '河南省高等学校计算机教育研究会：',
            mark: '尚展垒&nbsp;&nbsp;&nbsp;&nbsp;13838156565'
        },
        {
            name: '赛事组委会：',
            mark: '宋传德&nbsp;&nbsp;&nbsp;&nbsp;13333718788&nbsp;&nbsp;&nbsp;&nbsp;99081435@qq.com'
        },
    ],
    fotter:{a:'Copyright&nbsp;&nbsp;©&nbsp;&nbsp;2016-2024&nbsp;&nbsp;豫ICP备18013183号',
    b:'技术支持：河南省科联电子科技有限公司'},
    loginTip: '',
    tips: '提示：当您加入团队之后，信息将无法修改。',
    //民族
    ChinaEthnicityData: [{
        "id": "01",
        "name": "汉族"
    }, {
        "id": "02",
        "name": "蒙古族"
    }, {
        "id": "03",
        "name": "回族"
    }, {
        "id": "04",
        "name": "藏族"
    }, {
        "id": "05",
        "name": "维吾尔族"
    }, {
        "id": "06",
        "name": "苗族"
    }, {
        "id": "07",
        "name": "彝族"
    }, {
        "id": "08",
        "name": "壮族"
    }, {
        "id": "09",
        "name": "布依族"
    },
    {
        "id": "10",
        "name": "朝鲜族"
    }, {
        "id": "11",
        "name": "满族"
    }, {
        "id": "12",
        "name": "侗族"
    }, {
        "id": "13",
        "name": "瑶族"
    }, {
        "id": "14",
        "name": "白族"
    }, {
        "id": "15",
        "name": "土家族"
    },
    {
        "id": "16",
        "name": "哈尼族"
    }, {
        "id": "17",
        "name": "哈萨克族"
    }, {
        "id": "18",
        "name": "傣族"
    }, {
        "id": "19",
        "name": "黎族"
    }, {
        "id": "20",
        "name": "傈僳族"
    }, {
        "id": "21",
        "name": "佤族"
    },
    {
        "id": "22",
        "name": "畲族"
    }, {
        "id": "23",
        "name": "高山族"
    }, {
        "id": "24",
        "name": "拉祜族"
    }, {
        "id": "25",
        "name": "水族"
    }, {
        "id": "26",
        "name": "东乡族"
    }, {
        "id": "27",
        "name": "纳西族"
    },
    {
        "id": "28",
        "name": "景颇族"
    }, {
        "id": "29",
        "name": "柯尔克孜族"
    }, {
        "id": "30",
        "name": "土族"
    }, {
        "id": "31",
        "name": "达斡尔族"
    }, {
        "id": "32",
        "name": "仫佬族"
    }, {
        "id": "33",
        "name": "羌族"
    },
    {
        "id": "34",
        "name": "布朗族"
    }, {
        "id": "35",
        "name": "撒拉族"
    }, {
        "id": "36",
        "name": "毛难族"
    }, {
        "id": "37",
        "name": "仡佬族"
    }, {
        "id": "38",
        "name": "锡伯族"
    }, {
        "id": "39",
        "name": "阿昌族"
    },
    {
        "id": "40",
        "name": "普米族"
    }, {
        "id": "41",
        "name": "塔吉克族"
    }, {
        "id": "42",
        "name": "怒族"
    }, {
        "id": "43",
        "name": "乌孜别克族"
    }, {
        "id": "44",
        "name": "俄罗斯族"
    }, {
        "id": "45",
        "name": "鄂温克族"
    }, {
        "id": "46",
        "name": "崩龙族"
    }, {
        "id": "47",
        "name": "保安族"
    }, {
        "id": "48",
        "name": "裕固族"
    },
    {
        "id": "49",
        "name": "京族"
    }, {
        "id": "50",
        "name": "塔塔尔族"
    }, {
        "id": "51",
        "name": "独龙族"
    }, {
        "id": "52",
        "name": "鄂伦春族"
    }, {
        "id": "53",
        "name": "赫哲族"
    }, {
        "id": "54",
        "name": "门巴族"
    }, {
        "id": "55",
        "name": "珞巴族"
    }, {
        "id": "56",
        "name": "基诺族"
    }
    ],
    nianjiList:[
        '一年级', '二年级', '三年级', '四年级','五年级', '研一', '研二', '研三',
    ],
    yifuchima:['大','中','小'],
    skill:['安全策略','网络安全','云安全','可信计算','安全标准','其他',],
    SCAPThree:[
        {
            name:'报名组队',
            mark:'(7月26日 8:00~9月20日 18:00）'
        },
        {
            name:'线上赛',
            mark:'(09月21日 ~09月22日）'
        },{
            name:'线下赛',
            mark:'(10月26日 ~10月27日）'
        },
    ],
    // 登录、注册按钮是否显示的时间
	subTnTime:['2024-07-26 08:00:00','2024-10-30 18:00:00'],
	// 提示语显示的时间（如果按钮显示，就不会用到此参数）
    BaoMingTime:['2024-07-26 08:00:00','2024-09-20 18:00:00'],
}