<!-- eslint-disable no-const-assign -->
<template>
  <div style="height: 100vh" class="wwwscroll">
    <div class="loginHome">
      <div class="bannerOth">
        <img class="bannerImg" src="@/assets/banner.png" alt="" />
        <img
          class="bannerPhone bannerImg"
          src="@/assets/banner_phone.png"
          alt=""
        />
        <div class="header" v-if="stateObj.id != ''">
          <!-- 放顶部头像 -->
          <!-- <img
            class="tx"
            :src="stateObj.tx"
            alt="御网杯"
            @click="goHome = !goHome"
          /> -->

          <div
            class="name"
            style="border-radius: 5px 5px 0 0"
            @click="proxy.$router.push('./domyself')"
            v-show="goHome == true"
          >
            个人中心
          </div>
          <div
            class="name"
            style="color: red"
            @click.stop="logoOut"
            v-show="goHome == true"
          >
            退出登录
          </div>
        </div>

        <div
          class="suBmitBtn"
          v-if="!storage.session.get('sToken') && isSub == true"
        >
          <div class="btn" @click="toLogin"><span>登录</span></div>
          <div class="btn" @click="toRegister"><span>注册</span></div>
        </div>
        <div
          class="suBmitBtn"
          v-if="storage.session.get('sToken') && isSub == true"
        >
          <div
            class="btn"
            @click="toChaStudent"
            v-if="storage.session.get('user_type') == '教师'"
          >
            <span>学生名单</span>
          </div>
          <div
            class="btn"
            @click="toZuDui"
            v-if="storage.session.get('user_type') == '教师'"
          >
            <span>组建团队</span>
          </div>
          <div class="btn" @click="toChaXun"><span>我的团队</span></div>

          <div
            class="btn"
            @click="toAllchaxun"
            v-if="
              storage.session.get('user_type') == '教师' &&
              xxlxy == `${stateObj.name}-${stateObj.phone}`
            "
          >
            <span>学校团队</span>
          </div>
        </div>
        <div class="suBmitBtn" v-show="isSub == false">
          报名组队时间：{{ CONFIG.BaoMingTime[0] }}至{{ CONFIG.BaoMingTime[1] }}
        </div>
      </div>
      <div class="bannerIner">
        <!-- 大赛简介 -->
        <div class="brief container_1200">
          <div class="headline">
            <span>关于大赛</span>
          </div>
          <div class="details">
            <!-- <p class="title">{{ CONFIG.DSJJ.title }}</p> -->
            <p class="mark" v-html="CONFIG.DSJJ.mark"></p>
          </div>
        </div>
        <!-- 报名流程 -->
        <div class="brief container_1200">
          <div class="headline phoneHeadLine">
            <span>报名流程</span>
          </div>
          <div class="detailBox">
            <div class="circle">
              <img src="@/assets/666.png" />
              <div
                class="step step1"
                @mouseover="clickStep(1)"
                :class="nowStep == 1 ? 'stepactive' : ''"
              >
                <img src="@/assets/register.png" alt="" />
                <p>1.注册</p>
                <img class="act" src="@/assets/setp.png" alt="" />
              </div>
              <div
                class="step step2"
                @mouseover="clickStep(2)"
                :class="nowStep == 2 ? 'stepactive' : ''"
              >
                <img src="@/assets/wsxx.png" alt="" />
                <p>2.报名</p>
                <img class="act" src="@/assets/setp.png" alt="" />
              </div>
              <div
                class="step step3"
                @mouseover="clickStep(3)"
                :class="nowStep == 3 ? 'stepactive' : ''"
              >
                <img src="@/assets/invite.png" alt="" />
                <p>3.组队</p>
                <img class="act" src="@/assets/setp.png" alt="" />
              </div>
              <!-- <div
                class="step step4"
                @mouseover="clickStep(4)"
                :class="nowStep == 4 ? 'stepactive' : ''"
              >
                <img src="@/assets/upload.png" alt="" />
                <p>4.提交资料</p>
                <img class="act" src="@/assets/setp.png" alt="" />
              </div> -->
              <div
                class="step step5"
                @mouseover="clickStep(5)"
                :class="nowStep == 5 ? 'stepactive' : ''"
              >
                <img src="@/assets/success.png" alt="" />
                <p>4.公示</p>
                <img class="act" src="@/assets/setp.png" alt="" />
              </div>
            </div>
            <div class="remark">
              <div class="remarks">
                <div class="re_title">{{ findDetail().title }}</div>
                <div class="re_detail" v-html="findDetail().mark"></div>
              </div>
            </div>
          </div>
          <div class="detailBox_phone">
            <!-- 原来的手机端样式 -->
            <!-- <div class="bm_item">
              <div class="img"><img src="@/assets/phone/register.png" /></div>
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLC[0]?.title }}
                </div>
                <div class="mark" v-html="CONFIG.BMLC[0]?.mark"></div>
              </div>
            </div>
            <div class="bm_item">
              <div class="img"><img src="@/assets/phone/wsxx.png" /></div>
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLC[1]?.title }}
                </div>
                <div class="mark" v-html="CONFIG.BMLC[1]?.mark"></div>
              </div>
            </div>
            <div class="bm_item">
              <div class="img"><img src="@/assets/phone/invite.png" /></div>
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLC[2]?.title }}
                </div>
                <div class="mark" v-html="CONFIG.BMLC[2]?.mark"></div>
              </div>
            </div>
            <div class="bm_item">
              <div class="img"><img src="@/assets/phone/upload.png" /></div>
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLC[3]?.title }}
                </div>
                <div class="mark" v-html="CONFIG.BMLC[3]?.mark"></div>
              </div>
            </div>
            <div class="bm_item">
              <div class="img"><img src="@/assets/phone/success.png" /></div>
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLC[4]?.title }}
                </div>
                <div class="mark" v-html="CONFIG.BMLC[4]?.mark"></div>
              </div>
            </div> -->

            <div class="bm_item">
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLCPhone[0]?.G1 }}
                </div>
                <!-- <div class="mark" v-html="CONFIG.BMLC[0]?.mark"></div> -->
                <div class="mark" v-html="CONFIG.BMLCPhone[0]?.N1"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[0]?.N2"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[0]?.N3"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[0]?.N4"></div>
              </div>
            </div>
            <div class="bm_item">
              <div class="box">
                <div class="title">
                  {{ CONFIG.BMLCPhone[1]?.G1 }}
                </div>
                <!-- <div class="mark" v-html="CONFIG.BMLC[0]?.mark"></div> -->
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N1"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N2"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N3"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N4"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N41"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N42"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N43"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N44"></div>
                <div class="mark" v-html="CONFIG.BMLCPhone[1]?.N45"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 赛程安排 -->
        <div class="brief container_1200">
          <div class="headline">
            <span>赛程安排</span>
          </div>
          <div class="s_nBox">
            <img class="s_nBox_img" src="@/assets/scapimg.png" />
            <img class="s_nBox_img_phone" src="@/assets/phone/scapimg.png" />
            <div class="s_nItem">
              <div
                class="It"
                v-for="(item, index) in CONFIG.SCAPThree"
                :key="index"
              >
                <p class="title">{{ item.name }}</p>
                <p class="mark" v-html="item.mark"></p>
              </div>
            </div>
          </div>
          <!-- <div class="s_detailBox">
            <div
              class="s_item"
              v-for="(item, index) in CONFIG.SCAP"
              :key="index"
            >
              <div class="s_ttile">{{ item.name }}</div>
              <div class="s_mark" v-html="item.mark"></div>
            </div>
          </div> -->
        </div>
        <!-- 奖项设置 -->
        <div class="brief container_1200">
          <div class="headline">
            <span>奖项设置</span>
          </div>
          <div class="jx_tab">
            <div
              @click="jx_show = 1"
              :class="jx_show == 1 ? 'active_class' : ''"
            >
              线上赛
            </div>
            <div
              @click="jx_show = 2"
              :class="jx_show == 2 ? 'active_class' : ''"
            >
              线下赛
            </div>
          </div>
          <!-- 线上赛 -->
          <div class="j_detailBox" v-show="jx_show == 1">
            <div class="j_item">
              <img class="j_img" src="@/assets/jxszNew/xs1.png" alt="" />
              <p>{{ CONFIG.XSJXSZ[0] }}</p>
              <img class="o_img" src="@/assets/base_line.png" alt="" />
            </div>
            <div class="j_item">
              <img class="j_img" src="@/assets/jxszNew/xs2.png" alt="" />
              <p>{{ CONFIG.XSJXSZ[1] }}</p>
              <img class="o_img" src="@/assets/base_line.png" alt="" />
            </div>
            <div class="j_item">
              <img class="j_img" src="@/assets/jxszNew/xs3.png" alt="" />
              <p>{{ CONFIG.XSJXSZ[2] }}</p>
              <img class="o_img" src="@/assets/base_line.png" alt="" />
            </div>
          </div>
          <!-- 线下赛 -->
          <div class="j_detailBox" v-show="jx_show == 2">
            <div class="j_item">
              <img class="j_img" src="@/assets/jxszNew/xx1.png" alt="" />
              <p>{{ CONFIG.JXSZ[0] }}</p>
              <img class="o_img" src="@/assets/base_line.png" alt="" />
            </div>
            <div class="j_item">
              <img class="j_img" src="@/assets/jxszNew/xx2.png" alt="" />
              <p>{{ CONFIG.JXSZ[1] }}</p>
              <img class="o_img" src="@/assets/base_line.png" alt="" />
            </div>
            <div class="j_item">
              <img class="j_img" src="@/assets/jxszNew/xx3.png" alt="" />
              <p>{{ CONFIG.JXSZ[2] }}</p>
              <img class="o_img" src="@/assets/base_line.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 赛项组织 -->
        <div class="brief container_1200">
          <div class="headline">
            <span>赛项组织</span>
          </div>
          <div class="sx_detailBox">
            <div v-for="(item, index) in CONFIG.SXZZ" :key="index">
              <div class="sx_title">
                {{ item.name }}
              </div>
              <div class="sx_box">
                <div class="b_item" v-for="(it, ind) in item.chid" :key="ind">
                  {{ it }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 联系我们 -->
        <div class="brief container_1200">
          <div class="headline">
            <span>联系我们</span>
          </div>
          <div class="lx_detailBox">
            <div v-for="(item, index) in CONFIG.LXWM" :key="index">
              <!-- <img src="@/assets/jiantou.png" alt="" /> -->
              <div class="lx_title">
                <img src="@/assets/jiantou.png" alt="" />{{ item.name }}
              </div>
              <div class="lx_mark" v-html="item.mark"></div>
            </div>
          </div>
          <img class="lx_detailImg" src="@/assets/fot_line.png" alt="" />
        </div>
        <!-- 底部 -->
        <div class="fotter">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            ><p v-html="CONFIG.fotter.a"></p
          ></a>
          <p v-html="CONFIG.fotter.b"></p>
        </div>
      </div>
    </div>
    <!-- 注册弹框 -->
    <div class="register">
      <Modal
        class-name="register1"
        v-model="register_dal"
        title="账号注册"
        :mask-closable="false"
        :footer-hide="true"
      >
        <!-- <div class="check">
          <div class="item" @click="rClick(2)">
            <img
              v-if="registerObj.type == 1"
              src="@/assets/checkno.png"
              alt=""
            />
            <img
              v-if="registerObj.type == 2"
              src="@/assets/checkin.png"
              alt=""
            />
            我是教师
          </div>
          <div class="item" @click="rClick(1)">
            <img
              v-if="registerObj.type == 2"
              src="@/assets/checkno.png"
              alt=""
            />
            <img
              v-if="registerObj.type == 1"
              src="@/assets/checkin.png"
              alt=""
            />
            我是学生
          </div>
          <div class="remark">提示：身份注册后不可更改</div>
        </div> -->
        <div class="form">
          <div class="item">
            <div class="title"><img src="@/assets/phone.png" alt="" /></div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="registerObj.phone"
                placeholder="请输入手机号"
                @on-blur="blurPhone"
              />
            </div>
          </div>
          <div class="item">
            <div class="title"><img src="@/assets/password.png" alt="" /></div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="registerObj.password"
                placeholder="请设置密码（最少6位）"
              />
            </div>
          </div>

          <div class="item">
            <div class="title"><img src="@/assets/zhao.png" alt="" /></div>
            <div class="box">
              <Select v-model="registerObj.user_type" style="width: 100%">
                <Option value="教师">教师</Option>
                <Option value="本科学生">本科学生</Option>
                <Option value="高职高专学生">高职高专学生</Option>
                <Option value="中职学生">中职学生</Option>
              </Select>
            </div>
          </div>

          <div class="itemB">
            <div class="title">
              <Input
                style="width: 100%"
                v-model="registerObj.yzm"
                placeholder="图形验证码"
              />
            </div>
            <div class="box" @click="getLoginCaptcha">
              <img :src="txyzm" alt="" />
            </div>
          </div>
          <div class="itemB">
            <div class="title">
              <Input
                style="width: 100%"
                v-model="registerObj.yzm2"
                placeholder="验证码"
              />
            </div>
            <div class="box" @click="getPhoneNum">
              <div class="btn">{{ yzmNewTitle }}</div>
            </div>
          </div>
        </div>
        <div class="submit" @click="registerOk">立即注册</div>
        <div class="fotter">已有账号?<span @click="toLogin">请登录</span></div>
      </Modal>
    </div>
    <!-- 登录弹框 -->
    <div class="register">
      <Modal
        class-name="register1"
        v-model="login_dal"
        title="账号登录"
        :mask-closable="false"
        :footer-hide="true"
      >
        <div
          class="check"
          style="text-align: center; line-height: 40px; height: 40px"
          v-html="CONFIG.loginTip"
        ></div>
        <div class="form">
          <div class="item">
            <div class="title"><img src="@/assets/phone.png" alt="" /></div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="loginObj.phone"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="item">
            <div class="title"><img src="@/assets/password.png" alt="" /></div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="loginObj.password"
                placeholder="请输入密码"
                password
                type="password"
              />
            </div>
          </div>
          <div class="itemB">
            <div class="title">
              <Input
                style="width: 100%"
                v-model="loginObj.yzm"
                placeholder="图形验证码"
              />
            </div>
            <div class="box" @click="getdlCaptcha">
              <img :src="dlyzm" alt="" />
            </div>
          </div>
        </div>
        <div class="submit" @click="loginOk">登录</div>
        <div class="fotter">忘记密码?<span @click="towjmm">点击找回</span></div>
      </Modal>
    </div>
    <!-- 找回密码 -->
    <div class="register">
      <Modal
        class-name="register1"
        v-model="wjmm_dal"
        title="找回密码"
        :mask-closable="false"
        :footer-hide="true"
      >
        <div class="form">
          <div class="item">
            <div class="title"><img src="@/assets/zhao.png" alt="" /></div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="wjmmObj.phone"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="item">
            <div class="title"><img src="@/assets/password.png" alt="" /></div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="wjmmObj.password"
                placeholder="请设置密码"
              />
            </div>
          </div>
          <div class="itemB">
            <div class="title">
              <Input
                style="width: 100%"
                v-model="wjmmObj.yzm"
                placeholder="图形验证码"
              />
            </div>
            <div class="box" @click="getWJMMCaptcha">
              <img :src="WJMM" alt="" />
            </div>
          </div>
          <div class="itemB">
            <div class="title">
              <Input
                style="width: 100%"
                v-model="wjmmObj.yzm2"
                placeholder="验证码"
              />
            </div>
            <div class="box" @click="getwjyzm">
              <div class="btn">{{ wjyzmNewTitle }}</div>
            </div>
          </div>
        </div>
        <div class="submit" style="margin-top: 50px" @click="wjmmok">
          点击更改
        </div>
      </Modal>
    </div>

    <!-- 下面是组队的处理 -->
    <div class="teamUp">
      <Modal
        class-name="teamUp1"
        v-model="team_mdl"
        title=""
        :mask-closable="false"
        :footer-hide="true"
        :closable="false"
        :draggable="true"
        :sticky="true"
      >
        <div class="check">
          <div
            class="item"
            :class="zClick == 1 ? 'itemD' : ''"
            @click="zClick = 1"
          >
            教师组
          </div>
          <div
            class="item"
            :class="zClick == 2 ? 'itemD' : ''"
            @click="zClick = 2"
          >
            学生组
          </div>
          <!-- <div
            class="item"
            :class="zClick == 3 ? 'itemD' : ''"
            @click="zClick = 3"
          >
            应急响应赛组队
          </div> -->
        </div>
        <!-- 学生组队 -->
        <div class="form" style="padding-top: 12px" v-if="zClick == 2">
          <div class="item">
            <div class="title">学校联络员</div>
            <div class="box">
              <Input
                style="width: 100%"
                v-model="xxlxy"
                placeholder="学校联络员"
                readonly
              />
            </div>
          </div>

          <div class="item">
            <div class="title">学生组别</div>
            <div
              class="box"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 5px;
              "
            >
              <RadioGroup
                v-model="xszdObj.team_type"
                v-if="studentType.length > 0"
              >
                <Radio :label="item" v-for="item in studentType">
                  <span>{{ item }}</span>
                </Radio>

                <!-- <Radio :label="'本科组'" >     
                  <span>本科组</span>
                </Radio>
                <Radio :label="'高职组'">
                  <span>高职组</span>
                </Radio>
                <Radio :label="'中职组'">
                  <span>中职组</span>
                </Radio> -->
              </RadioGroup>
              <span style="color: red" v-else
                >个人赛报名人数不足，暂无法组队。</span
              >
            </div>
          </div>

          <div class="item" v-if="studentType.length > 0">
            <div class="title">团队昵称</div>
            <div class="box">
              <!-- v-model="wjmmObj.password" -->
              <Input
                style="width: 100%"
                placeholder="团队昵称"
                maxlength="10"
                v-model="xszdObj.team_nikename"
              />
            </div>
          </div>
          <div class="itemB" v-if="studentType.length > 0">
            <div class="itemBT itemBD">
              <div class="T1">队员</div>
              <div class="T2">手机号</div>
              <div class="T3">姓名</div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">指导教师1</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  readonly
                  v-model="stateObj.phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="stateObj.name"
                  placeholder="姓名"
                  readonly
                  maxlength="10"
                />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">指导教师2</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  v-model="xszdObj.teacher2_phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="xszdObj.teacher2_name"
                  placeholder="姓名"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队长</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  v-model="xszdObj.leader_phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="xszdObj.leader_name"
                  placeholder="姓名"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队员1</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  v-model="xszdObj.member_1_phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="xszdObj.member_1_name"
                  placeholder="姓名"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队员2</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  v-model="xszdObj.member_2_phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="xszdObj.member_2_name"
                  placeholder="姓名"
                  maxlength="10"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 教师组队 -->
        <div class="form" style="padding-top: 60px" v-if="zClick == 1">
          <div class="item">
            <div class="title">学校联络员</div>
            <div class="box">
              <!-- v-model="wjmmObj.phone" -->
              <Input
                style="width: 100%"
                v-model="xxlxy"
                placeholder="学校联络员"
                readonly
              />
            </div>
          </div>
          <div class="item">
            <div class="title">团队昵称</div>
            <div class="box">
              <!-- v-model="wjmmObj.password" -->
              <Input
                style="width: 100%"
                placeholder="团队昵称"
                maxlength="10"
                v-model="jszdObj.team_nikename"
              />
            </div>
          </div>
          <div class="itemB" style="margin-top: 50px; margin-bottom: 50px">
            <div class="itemBT itemBD">
              <div class="T1">队员</div>
              <div class="T2">手机号</div>
              <div class="T3">姓名</div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队长</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  readonly
                  v-model="stateObj.phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="stateObj.name"
                  placeholder="姓名"
                  readonly
                  maxlength="10"
                />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队员1</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  v-model="jszdObj.member_1_phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="jszdObj.member_1_name"
                  placeholder="姓名"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队员2</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                  v-model="jszdObj.member_2_phone"
                />
              </div>
              <div class="T3">
                <Input
                  style="width: 100%"
                  v-model="jszdObj.member_2_name"
                  placeholder="姓名"
                  maxlength="10"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form" v-if="zClick == 3">
          <div class="item">
            <div class="title">学校联络员</div>
            <div class="box">
              <!-- v-model="wjmmObj.phone" -->
              <Input style="width: 100%" placeholder="学校联络员" readonly />
            </div>
          </div>
          <div class="item">
            <div class="title">团队昵称</div>
            <div class="box">
              <!-- v-model="wjmmObj.password" -->
              <Input
                style="width: 100%"
                placeholder="团队昵称"
                maxlength="10"
              />
            </div>
          </div>
          <div class="itemB">
            <div class="itemBT itemBD">
              <div class="T1">队员</div>
              <div class="T2">手机号</div>
              <div class="T3">姓名</div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">指导教师1</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                />
              </div>
              <div class="T3">
                <Input style="width: 100%" placeholder="姓名" maxlength="10" />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">指导教师2</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                />
              </div>
              <div class="T3">
                <Input style="width: 100%" placeholder="姓名" maxlength="10" />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队长</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                />
              </div>
              <div class="T3">
                <Input style="width: 100%" placeholder="姓名" maxlength="10" />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队员1</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                />
              </div>
              <div class="T3">
                <Input style="width: 100%" placeholder="姓名" maxlength="10" />
              </div>
            </div>
            <div class="itemBA itemBD">
              <div class="T1">队员2</div>
              <div class="T2">
                <Input
                  style="width: 100%"
                  placeholder="手机号"
                  maxlength="11"
                />
              </div>
              <div class="T3">
                <Input style="width: 100%" placeholder="姓名" maxlength="10" />
              </div>
            </div>
          </div>
        </div>
        <div class="submit">
          <div class="btn btnC" @click="zuduicancel">返回</div>
          <div class="btn btnO" @click="zuduiok">组队</div>
        </div>
      </Modal>
    </div>

    <!-- 下面是查询组队的处理 -->
    <div class="teamUp">
      <Modal
        class-name="teamUp1 teamUp2 "
        v-model="teamcx_mdl"
        title=""
        :mask-closable="false"
        :footer-hide="true"
      >
        <div class="checkCx">组队查询</div>
        <div class="checkBox wwwscroll">
          <Spin fix v-show="spinFlagZdxx">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
          <div
            style="
              color: #ffff;
              text-align: center;
              height: 60px;
              line-height: 60px;
              width: 100%;
            "
            v-if="zuduiList.length == 0"
          >
            暂时没有队伍...
          </div>
          <div
            class="checkItem"
            v-for="(item, index) in zuduiList"
            :key="index"
          >
            <div
              class="jsicon"
              v-if="
                (item.team_type == '教师组' &&
                  item.leader.id == stateObj?.id) ||
                (item.team_type != '教师组' && item.teacher1.id == stateObj?.id)
              "
              @click="delteam(item)"
            >
              解散
            </div>
            <div class="item_hea">
              <div class="title">团队昵称</div>
              <div class="detail one_ell">{{ item.team_nikename }}</div>
            </div>
            <div class="item_hea">
              <div class="title">团队类型</div>
              <div class="detail one_ell">{{ item.team_type }}</div>
            </div>
            <div class="item_hea" v-if="item.teacher1?.name">
              <div class="title">指导教师1</div>
              <div class="detail">
                {{ item.teacher1?.name }}-{{ item.teacher1?.phone }}
              </div>
            </div>
            <div class="item_hea" v-if="item.teacher2?.name">
              <div class="title">指导教师2</div>
              <div class="detail one_ell">
                {{ item.teacher2?.name }}-{{ item.teacher2?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队长</div>
              <div class="detail one_ell">
                {{ item.leader?.name }}-{{ item.leader?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员1</div>
              <div class="detail one_ell">
                {{ item.member1?.name }}-{{ item.member1?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员2</div>
              <div class="detail one_ell">
                {{ item.member2?.name }}-{{ item.member2?.phone }}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>

    <!-- 下面是查询组队的处理 -->
    <div class="teamUp">
      <Modal
        class-name="teamUp1 teamUp3"
        v-model="teamup_mdl"
        title=""
        :mask-closable="false"
        :footer-hide="true"
      >
        <Spin fix v-show="spinFlagZdls">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>Loading</div>
        </Spin>
        <div class="checkCx">组队查询</div>
       
        <div class="uploadBox"  v-if="zuupduiList?.length > 0">
          <div
            @click="newAdd"
            style="
              color: #38d1fb;
              display: flex;
              align-items: center;
              cursor: pointer;
              width: 145px;
            "
          >
            <div
              style="
                height: 32px;
                width: 100%;
                border: 1px solid #fff;
                border-radius: 5px;
                line-height: 32px;
                text-align: center;
                margin-top: -8px;
                color: #000;
                background: #fff;
              "
            >
              <Icon
                type="ios-cloud-download-outline"
                style="color: #38d1fb; font-weight: 600"
              />下载报名表
            </div>
          </div>

          

          <div class="uploadBox1">
            <Upload :before-upload="handleUpload" action="/">
              <Button style="width: 145px;"
                ><Icon
                  type="ios-cloud-upload-outline"
                  style="font-weight: 600;"
                />上传报名表</Button
              >
              <p style="position: absolute; color: red; top: 80px; right: 0px;font-size: 12px;">
            支持上传JPG、JPEG、PNG类型图片，且图片大小不能大于5M
          </p>
            </Upload>
          </div>
          <Image
            style="
              min-width: 100px;
            "
            v-show="zuupduiList[0]?.contact_image_abs_path != ''"
            :src="showImg"
            :preview-list="urlList"
            fit="cover"
            preview
            :initial-index="index"
          />
          
          <!-- <p style="position: absolute; color: red; top: 140px; right: 95px">
            仅可上传JPG、JPEG、PNG类型图片，且图片大小不能大于5M
          </p> -->
        </div>

        <div class="checkBox checkBoxB wwwscroll">
          <div
            style="
              color: #ffff;
              text-align: center;
              height: 60px;
              line-height: 60px;
              width: 100%;
            "
            v-if="zuupduiList.length == 0"
          >
            暂时没有队伍...
          </div>
          <div
            class="checkItem"
            v-for="(item, index) in zuupduiList"
            :key="index"
          >
            <div class="item_hea">
              <div class="title">团队昵称</div>
              <div class="detail one_ell">{{ item.team_nikename }}</div>
            </div>
            <div class="item_hea">
              <div class="title">团队类型</div>
              <div class="detail one_ell">{{ item.team_type }}</div>
            </div>
            <div class="item_hea" v-if="item.teacher1?.name">
              <div class="title">指导教师1</div>
              <div class="detail one_ell">
                {{ item.teacher1?.name }}-{{ item.teacher1?.phone }}
              </div>
            </div>
            <div class="item_hea" v-if="item.teacher2?.name">
              <div class="title">指导教师2</div>
              <div class="detail one_ell">
                {{ item.teacher2?.name }}-{{ item.teacher2?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队长</div>
              <div class="detail one_ell">
                {{ item.leader?.name }}-{{ item.leader?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员1</div>
              <div class="detail one_ell">
                {{ item.member1?.name }}-{{ item.member1?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员2</div>
              <div class="detail one_ell">
                {{ item.member2?.name }}-{{ item.member2?.phone }}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>

    <!-- 下面是查询组队的处理 -->
    <div class="teamUp">
      <Modal
        class-name="teamUp1 teamUp4"
        v-model="teamup_baoming"
        title=""
        :mask-closable="false"
        :footer-hide="true"
      >
        <div class="checkCx">学生查询</div>

        <div class="checkBox checkBoxB wwwscroll">
          <Spin fix v-show="spinFlagXscx">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
          <div
            style="
              color: #ffff;
              text-align: center;
              height: 60px;
              line-height: 60px;
            "
            v-if="baominglist.length == 0"
          >
            暂时没有学生报名...
          </div>

          <div
            class="checkItem"
            v-for="(item, index) in baominglist"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div>{{ item.phone }}</div>
            <div>{{ item.user_type }}</div>
          </div>
        </div>
      </Modal>
    </div>
    <down-file ref="downFileNull"></down-file>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
import moment from "moment";
import {
  getCaptcha,
  sendRegisterCode,
  doRegister,
  doLogin,
  doChangePwd,
  sendChangePwdCode,
  isMyInfoPerfect,
  getSchoolContactsUser,
  createTeacherTeam,
  createStudentTeam,
  getMyCreatTeam,
  delMyTeam,
  checkMySchoolStudent,
  getTeamType,
  getSchoolAllTeams,
  uploadSchoolContactImage,
  getMyTeam,
} from "@/api/ViewScreen";
import { debounce } from "@/utils/AntiShake";
import downFile from "./downFile";
const { proxy } = getCurrentInstance();
let stateObj = reactive({
  tx: storage.session.get("showImg")
    ? storage.session.get("showImg")
    : "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201911%2F21%2F20191121195046_fktqa.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1709256536&t=4125a729a5f1bbac8266b62f827975d2",
  name: storage.session.get("name") ? storage.session.get("name") : "御网杯",
  user_type: storage.session.get("user_type")
    ? storage.session.get("user_type")
    : "",
  phone: storage.session.get("phone") ? storage.session.get("phone") : "",
  id: storage.session.get("id") ? storage.session.get("id") : "",
});
//声明变量
const CONFIG = window.CONFIG;

//判断是否进入报名时间
const SubFlag = () => {
  let time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  if (time >= CONFIG.subTnTime[0] && time <= CONFIG.subTnTime[1]) {
    return true;
  } else {
    return false;
  }
};
const isSub = ref(SubFlag());

//控制个人中心 退出按钮
const goHome = ref(true);

// 报名流程点击事件
const nowStep = ref(1);
const clickStep = (num) => {
  nowStep.value = num;
};
//报名流程渲染工具函数
const findDetail = () => {
  let arr = CONFIG.BMLC;
  const obj = arr.filter((item) => {
    return item.step == nowStep.value;
  });
  return obj[0];
};
//手机号正则
const getRegPhone = (phone) => {
  var regExp = /^1[3456789]\d{9}$/;
  if (regExp.test(phone)) {
    return true;
  } else {
    return false;
  }
};
//注册变量
//注册倒计时
let yzmNewTitle = ref("获取短信验证码");
let yzmNewFlag = ref(false);
let timeNew = ref(60);
let timer = "";
const setIntervalfn = () => {
  if (yzmNewFlag.value) {
    return;
  } else {
    yzmNewFlag.value = true;
    timer = setInterval(() => {
      if (yzmNewFlag.value == true) {
        // console.log("触发");
        yzmNewTitle.value = timeNew.value + " s";
        timeNew.value--;
        callback(timeNew);
      }
    }, 1000);
    const callback = (timeNew) => {
      // console.log(timeNew.value);
      if (timeNew.value < 0) {
        timeNew.value = 60;
        yzmNewFlag.value = false;
        yzmNewTitle.value = "获取验证码";
        console.log(yzmNewFlag.value);
        clearInterval(timer);
      }
    };
  }
};
let register_dal = ref(false);

let registerObj = reactive({
  user_type: "", //0是教师 1是学生
  phone: "", //手机号
  password: "", //密码
  yzm: "", //图形验证码
  yzm2: "", //短信验证码
  CAPTCHA: "", //数字验证码标识
});
const rClick = (val) => {
  registerObj.type = val;
};
const registerInit = () => {
  // eslint-disable-next-line no-const-assign
  registerObj = reactive({
    user_type: "", //0是教师 1是学生
    phone: "", //手机号
    password: "", //密码
    yzm: "", //图形验证码
    yzm2: "", //短信验证码
    CAPTCHA: "", //数字验证码标识
  });
  timeNew.value = 60;
  yzmNewFlag.value = false;
  yzmNewTitle.value = "获取验证码";
  console.log(yzmNewFlag.value);
  clearInterval(timer);
};
const toRegister = () => {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth <= 500) {
    proxy.$router.push("./registerPhone");
  } else {
    register_dal.value = true;
    // eslint-disable-next-line no-const-assign
    registerInit();
    getLoginCaptcha();
  }
};
const blurPhone = () => {
  if (registerObj.phone) {
    if (getRegPhone(registerObj.phone)) {
      return;
    } else {
      proxy.$Message.warning("请输入正确的手机号码");
    }
  } else {
    proxy.$Message.warning("请输入手机号码");
  }
};
let CAPTCHA_SIGN = ref(""); //图片验证码随机签名
let txyzm = ref("");
//获取验证码
const getLoginCaptcha = debounce(() => {
  registerObj.yzm = "";
  CAPTCHA_SIGN.value = "";
  CAPTCHA_SIGN.value = Math.floor(Date.now() / 1000);
  // Math.ceil(Math.random() * 10000000000 + 1);
  // 请求验证码后放到这里
  txyzm.value = getCaptcha({ scene: "register", sign: CAPTCHA_SIGN.value });
}, 500);
//注册 获取手机验证码
const getPhoneNum = debounce(() => {
  if (yzmNewFlag.value) {
    return;
  }
  if (registerObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (registerObj.user_type == "") {
    proxy.$Message.warning("请选择用户身份");
    return;
  }
  if (registerObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  sendRegisterCode({
    phone: registerObj.phone,
    user_type: registerObj.user_type,
    sign: CAPTCHA_SIGN.value,
    captcha: registerObj.yzm,
  }).then((res) => {
    console.log(res);
    if (res.code == 1) {
      setIntervalfn();
    } else {
      registerObj.yzm = "";
      getLoginCaptcha();
      proxy.$Message.error(res.msg);
    }
  });
}, 1000);
//注册执行函数
const registerOk = debounce(() => {
  if (registerObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (registerObj.password == "" || registerObj.password.length < 6) {
    proxy.$Message.warning("请设置密码且密码不能少于6位");
    return;
  }
  if (registerObj.user_type == "") {
    proxy.$Message.warning("请选择用户身份");
    return;
  }

  if (registerObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  if (registerObj.yzm2 == "") {
    proxy.$Message.warning("请输入手机验证码");
    return;
  }
  doRegister({
    phone: registerObj.phone,
    code: registerObj.yzm2,
    password: registerObj.password,
    user_type: registerObj.user_type,
  }).then((res) => {
    if (res.code == 1) {
      proxy.$Message.success(res.msg);
      register_dal.value = false;
    } else {
      getLoginCaptcha();
      proxy.$Message.error(res.msg);
    }
  });
}, 500);
//账号登录
let login_dal = ref(false);
let loginObj = reactive({
  phone: "", //手机号
  password: "", //密码
  yzm: "",
});
const loginInit = () => {
  // eslint-disable-next-line no-const-assign
  loginObj = reactive({
    phone: "", //手机号
    password: "", //密码
    yzm: "",
  });
};
let dl_SIGN = ref(""); //图片验证码随机签名
let dlyzm = ref("");
//获取验证码
const getdlCaptcha = debounce(() => {
  loginObj.yzm = "";
  dl_SIGN.value = "";
  dl_SIGN.value = Math.floor(Date.now() / 1000);

  // Math.ceil(Math.random() * 10000000000 + 1);
  // 请求验证码后放到这里
  dlyzm.value = getCaptcha({ scene: "login", sign: dl_SIGN.value });
}, 500);
const toLogin = () => {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth <= 500) {
    proxy.$router.push("./loginPhone");
  } else {
    register_dal.value = false;
    login_dal.value = true;
    loginInit();
    getdlCaptcha();
  }
};
//登录执行函数
const loginOk = debounce(() => {
  if (loginObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (loginObj.password == "" || loginObj.password.length < 6) {
    proxy.$Message.warning("请输入密码且密码不能少于6位");
    return;
  }
  if (loginObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  doLogin({
    phone: loginObj.phone,
    password: loginObj.password,
    sign: dl_SIGN.value,
    captcha: loginObj.yzm,
  }).then((res) => {
    if (res.code == 1) {
      proxy.$Message.success(res.msg);
      login_dal.value = false;

      // 登录补充功能
      storage.session.set("sToken", res.data.token);
      isMyInfoPerfect({ token: storage.session.get("sToken") }).then((res1) => {
        if (res1.code == 1) {
          storage.session.set("name", res.data.name);
          storage.session.set(
            "showImg",
            process.env.VUE_APP_API_URL + res.data.head_img_abs_path
          );
          storage.session.set("user_type", res.data.user_type);
          storage.session.set("phone", res.data.phone);
          storage.session.set("id", res.data.id);
          stateObj.tx = storage.session.get("showImg");
          stateObj.name = storage.session.get("name");
          stateObj.phone = storage.session.get("phone");
          stateObj.id = storage.session.get("id");
          getLLY();
          console.log(stateObj);
          proxy.$forceUpdate();
          return;
        } else {
          proxy.$router.push("./domyself");
        }
      });
    } else {
      getdlCaptcha();
      proxy.$Message.error(res.msg);
    }
  });
}, 500);

//找回密码
let wjmm_dal = ref(false);
//倒计时
let wjyzmNewTitle = ref("获取短信验证码");
let wjyzmNewFlag = ref(false);
let wjtimeNew = ref(60);
let wjtimer = "";
const wjsetIntervalfn = () => {
  if (wjyzmNewFlag.value) {
    return;
  } else {
    wjyzmNewFlag.value = true;
    wjtimer = setInterval(() => {
      if (wjyzmNewFlag.value == true) {
        // console.log("触发");
        wjyzmNewTitle.value = wjtimeNew.value + " s";
        wjtimeNew.value--;
        callback(wjtimeNew);
      }
    }, 1000);
    const callback = (wjtimeNew) => {
      // console.log(timeNew.value);
      if (wjtimeNew.value < 0) {
        wjtimeNew.value = 60;
        wjyzmNewFlag.value = false;
        wjyzmNewTitle.value = "获取验证码";
        console.log(yzmNewFlag.value);
        clearInterval(wjtimer);
      }
    };
  }
};
let wjmmObj = reactive({
  phone: "", //手机号
  password: "", //密码
  yzm: "",
  yzm2: "",
});
const wjregisterInit = () => {
  // eslint-disable-next-line no-const-assign
  wjmmObj = reactive({
    phone: "", //手机号
    password: "", //密码
    yzm: "",
    yzm2: "",
  });
  wjtimeNew.value = 60;
  wjyzmNewFlag.value = false;
  wjyzmNewTitle.value = "获取验证码";
  clearInterval(wjtimer);
};
let WJMM_SIGN = ref(""); //图片验证码随机签名
let WJMM = ref("");
//获取验证码
const getWJMMCaptcha = debounce(() => {
  wjmmObj.yzm = "";
  WJMM_SIGN.value = "";
  WJMM_SIGN.value = Math.floor(Date.now() / 1000);

  // Math.ceil(Math.random() * 10000000000 + 1);
  // 请求验证码后放到这里
  WJMM.value = getCaptcha({ scene: "changePwd", sign: WJMM_SIGN.value });
}, 500);

const towjmm = () => {
  login_dal.value = false;
  wjmm_dal.value = true;
  // eslint-disable-next-line no-const-assign
  wjregisterInit();
  getWJMMCaptcha();
};
//忘记密码 获取手机验证码
const getwjyzm = debounce(() => {
  if (wjyzmNewFlag.value) {
    return;
  }
  if (wjmmObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (wjmmObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  sendChangePwdCode({
    phone: wjmmObj.phone,
    sign: WJMM_SIGN.value,
    captcha: wjmmObj.yzm,
  }).then((res) => {
    console.log(res);
    if (res.code == 1) {
      wjsetIntervalfn();
    } else {
      wjmmObj.yzm = "";
      getWJMMCaptcha();
      proxy.$Message.error(res.msg);
    }
  });
}, 1000);

//忘记密码执行函数
const wjmmok = debounce(() => {
  if (wjmmObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (wjmmObj.password == "" || wjmmObj.password.length < 6) {
    proxy.$Message.warning("请设置密码且密码不能少于6位");
    return;
  }
  if (wjmmObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  if (wjmmObj.yzm2 == "") {
    proxy.$Message.warning("请输入手机验证码");
    return;
  }
  doChangePwd({
    phone: wjmmObj.phone,
    password: wjmmObj.password,
    code: wjmmObj.yzm2,
  }).then((res) => {
    if (res.code == 1) {
      proxy.$Message.success(res.msg);
      wjmm_dal.value = false;
    } else {
      getWJMMCaptcha();
      proxy.$Message.error(res.msg);
    }
  });
}, 500);

//组队处理
let team_mdl = ref(false);
let zClick = ref(1);
let xxlxy = ref("");
// 查询学校联络员
const getLLY = () => {
  getSchoolContactsUser({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        xxlxy.value = `${res.data.name}-${res.data.phone}`;
      } else {
        return;
      }
    }
  );
};

let jszdObj = ref({
  //教师组队对象
  team_nikename: "",
  member_1_phone: "",
  member_1_name: "",
  member_2_phone: "",
  member_2_name: "",
});
let xszdObj = ref({
  //学生对组队对象
  team_type: "",
  team_nikename: "",
  teacher2_phone: "",
  teacher2_name: "",
  leader_phone: "",
  leader_name: "",
  member_1_phone: "",
  member_1_name: "",
  member_2_phone: "",
  member_2_name: "",
});
// let yjxyObj = ref({//应急响应组队对象
//   team_type:'',
//   team_nikename:'',
//   leader_phone:'',
//   leader_name:'',
//   member_1_phone:'',
//   member_1_name:'',
//   member_2_phone:'',
//   member_2_name:'',
// })
const initData = () => {
  jszdObj.value = {
    //教师组队对象
    team_nikename: "",
    member_1_phone: "",
    member_1_name: "",
    member_2_phone: "",
    member_2_name: "",
  };
  xszdObj.value = {
    //学生对组队对象
    team_type: "",
    team_nikename: "",
    teacher2_phone: "",
    teacher2_name: "",
    leader_phone: "",
    leader_name: "",
    member_1_phone: "",
    member_1_name: "",
    member_2_phone: "",
    member_2_name: "",
  };
};
//学生组队type
let studentType = ref([]);

//发起组队
const toZuDui = () => {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth <= 500) {
    proxy.$router.push("./teamPhone");
  } else {
    team_mdl.value = true;
    getTeamType({ token: storage.session.get("sToken") }).then((res) => {
      if (res.code == 1) {
        studentType.value = res.data;
      }
    });

    initData();
  }
};
//组队返回按钮
const zuduicancel = () => {
  team_mdl.value = false;
  initData();
};
//组队按钮
const zuduiok = debounce(() => {
  if (zClick.value == 1) {
    //教师组队逻辑
    if (jszdObj.value.team_nikename == "") {
      proxy.$Message.warning("请填写团队昵称");
      return;
    }
    if (jszdObj.value.member_1_phone == "") {
      proxy.$Message.warning("请填写队员1手机号");
      return;
    }
    if (jszdObj.value.member_1_name == "") {
      proxy.$Message.warning("请填写队员1姓名");
      return;
    }
    if (jszdObj.value.member_2_phone == "") {
      proxy.$Message.warning("请填写队员2手机号");
      return;
    }
    if (jszdObj.value.member_2_name == "") {
      proxy.$Message.warning("请填写队员2姓名");
      return;
    }
    jszdObj.value.token = storage.session.get("sToken");
    createTeacherTeam(jszdObj.value).then((res) => {
      if (res.code == 1) {
        proxy.$Message.success("组队成功");
        initData();
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else if (zClick.value == 2) {
    // 学生组队逻辑
    if (xszdObj.value.team_type == "") {
      proxy.$Message.warning("请选择学生组别");
      return;
    }
    if (xszdObj.value.team_nikename == "") {
      proxy.$Message.warning("请填写团队昵称");
      return;
    }
    if (xszdObj.value.leader_phone == "") {
      proxy.$Message.warning("请填写队长手机号");
      return;
    }
    if (xszdObj.value.leader_name == "") {
      proxy.$Message.warning("请填写队长姓名");
      return;
    }
    if (xszdObj.value.member_1_phone == "") {
      proxy.$Message.warning("请填写队员1手机号");
      return;
    }
    if (xszdObj.value.member_1_name == "") {
      proxy.$Message.warning("请填写队员1姓名");
      return;
    }
    if (xszdObj.value.member_2_phone == "") {
      proxy.$Message.warning("请填写队员2手机号");
      return;
    }
    if (xszdObj.value.member_2_name == "") {
      proxy.$Message.warning("请填写队员2姓名");
      return;
    }
    xszdObj.value.token = storage.session.get("sToken");
    createStudentTeam(xszdObj.value).then((res) => {
      if (res.code == 1) {
        proxy.$Message.success("组队成功");
        initData();
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else {
    //应急响应赛组队逻辑
  }
  // team_mdl.value = false;
  // initData()
}, 500);
//组队查询处理
const spinFlagZdxx = ref(false);
let teamcx_mdl = ref(false);
let zuduiList = ref([]);
const toChaXun = () => {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth <= 500) {
    proxy.$router.push("./cxPhone");
  } else {
    teamcx_mdl.value = true;
    zuduiList.value = [];
    spinFlagZdxx.value = true;
    if (storage.session.get("user_type") == "教师") {
      getMyCreatTeam({ token: storage.session.get("sToken") }).then((res) => {
        spinFlagZdxx.value = false;
        if (res.code == 1) {
          zuduiList.value = res.data;
        } else {
          proxy.$Message.error(res.msg);
        }
      });
    } else {
      getMyTeam({ token: storage.session.get("sToken") }).then((res) => {
        spinFlagZdxx.value = false;
        if (res.code == 1) {
          zuduiList.value = res.data;
          console.log(res.data);
        } else {
          proxy.$Message.error(res.msg);
        }
      });
    }
  }
};
//解散团队
const delteam = debounce((obj) => {
  proxy.$Modal.confirm({
    title: "删除提示",
    content: "<p>您确定解散此团队吗?</p>",
    onOk: () => {
      delMyTeam({ token: storage.session.get("sToken"), team_id: obj.id }).then(
        (res) => {
          if (res.code == 1) {
            toChaXun();
          } else {
            proxy.$Message.error(res.msg);
          }
        }
      );
    },
    onCancel: () => {
      proxy.$Message.warning("取消解散");
    },
  });
}, 1000);
//组队上传处理
let teamup_mdl = ref(false);
const spinFlagZdls = ref(false);
let zuupduiList = ref([]);
let showImg = ref(""); //上传文件展示
const urlList = ref([]);
const toAllchaxun = () => {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth <= 500) {
    proxy.$router.push("./schoolPhone");
  } else {
    teamup_mdl.value = true;
    spinFlagZdls.value = true;
    getSchoolAllTeams({ token: storage.session.get("sToken") }).then((res) => {
      spinFlagZdls.value = false;
      if (res.code == 1) {
        zuupduiList.value = res.data;
        if(zuupduiList.value.length == 0){
        }else{
          showImg.value =
          process.env.VUE_APP_API_URL + res.data[0].contact_image_abs_path;
          urlList.value = [showImg.value];
        }

        
        
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  }
};
let fileD = ref(); //选中图片文件

//上传头像
const handleUpload = (file) => {
  if (
    file.type == "image/jpeg" ||
    file.type == "image/jpg" ||
    file.type == "image/png"
  ) {
    if (file.size > 5 * 1024 * 1024) {
      proxy.$Message.warning("图片大小不能超过5M");
      return;
    }

    fileD.value = file;
    uploadSchoolContactImage({
      token: storage.session.get("sToken"),
      image: fileD.value,
    }).then((res) => {
      if (res.code == 1) {
        proxy.$Message.success(res.msg);
        getSchoolAllTeams({ token: storage.session.get("sToken") }).then(
          (res1) => {
            if (res1.code == 1) {
              zuupduiList.value = res1.data;
              if (zuupduiList.value.length == 0) {
                return;
              } else {

                showImg.value =
                  process.env.VUE_APP_API_URL +
                  res1.data[0].contact_image_abs_path;
                urlList.value = [showImg.value];
              }
            } else {
              proxy.$Message.error(res1.msg);
            }
          }
        );
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else {
    proxy.$Message.warning("仅支持上传jpg/png/jpeg类型的图片");
  }
};
const spinFlagXscx = ref(false);
//查询学校报名学
let baominglist = ref([]);
let teamup_baoming = ref(false);
const toChaStudent = () => {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth <= 500) {
    proxy.$router.push("./cxStudent");
  } else {
    teamup_baoming.value = true;
    spinFlagXscx.value = true;
    checkMySchoolStudent({ token: storage.session.get("sToken") }).then(
      (res) => {
        spinFlagXscx.value = false;
        if (res.code == 1) {
          baominglist.value = res.data.rows;
          console.log(res.data.rows, 1694);
        } else {
          // proxy.$Message.error(res.msg);
        }
      }
    );
  }
};
//退出登录

// logoOut
const logoOut = () => {
  proxy.$Modal.confirm({
    title: "退出提示",
    content: "<p>您确定退出登录吗?</p>",
    onOk: () => {
      storage.session.remove("name");
      storage.session.remove("showImg");
      storage.session.remove("user_type");
      storage.session.remove("phone");
      storage.session.remove("id");
      storage.session.remove("sToken");
      stateObj = {
        tx: storage.session.get("showImg")
          ? storage.session.get("showImg")
          : "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201911%2F21%2F20191121195046_fktqa.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1709256536&t=4125a729a5f1bbac8266b62f827975d2",
        name: storage.session.get("name")
          ? storage.session.get("name")
          : "御网杯",
        user_type: storage.session.get("user_type")
          ? storage.session.get("user_type")
          : "",
        phone: storage.session.get("phone") ? storage.session.get("phone") : "",
        id: storage.session.get("id") ? storage.session.get("id") : "",
      };
      proxy.$forceUpdate();
    },
    onCancel: () => {
      proxy.$Message.info("取消退出");
    },
  });
};
//下载信息表处理
const downFileNull = ref(null);

const newAdd = (val, obj) => {
  downFileNull.value.showUp();
};
//奖项设置查看

const jx_show = ref(1);

onMounted(() => {
  if (storage.session.get("user_type") == "教师") {
    getLLY();
  }
  if (storage.session.get("sToken")) {
    isMyInfoPerfect({ token: storage.session.get("sToken") }).then((res1) => {
      if (res1.code == 1) {
      } else {
        proxy.$router.push("./domyself");
      }
    });
  }
});
</script>
<style lang="less">
.teamUp1 .item .ivu-input {
  color: #badde0;
  background: rgba(0, 0, 0, 0);
  border: none;
  border: 1px solid #3486c0;
}
.teamUp1 .itemB .ivu-input {
  color: #badde0;
  background: rgba(0, 0, 0, 0);
  border: none !important;
}
.teamUp1 .item .ivu-radio-inner {
  background-color: #000;
}
.teamUp1 .item .ivu-radio-group-item span {
  color: #badde0;
}

.teamUp1 .itemB .ivu-input {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #44f2ff;
  text-align: center;
  color: #badde0;
}

.teamUp1 .ivu-modal {
  width: 600px !important;
  height: 529px;
  background: url("../assets/zudui.png");
  background-size: 100% 100%;
}
.teamUp3 .ivu-modal {
  width: 858px !important;
  height: 696px;
}
.teamUp1 .ivu-modal .ivu-modal-content {
  width: 100% !important;
  height: 100% !important;
  background: url("../assets/zudui.png");
  background-size: 100% 100%;
}
.teamUp1 .ivu-modal .ivu-modal-content .ivu-modal-header {
  width: 100% !important;
  height: 50px !important;
  padding: 0;
  border-bottom: none;
}
.teamUp1 .ivu-modal .ivu-modal-content .ivu-modal-close {
  right: 22px;
  top: 32px;
  z-index: 1;
}
.teamUp1 .ivu-modal .ivu-modal-content .ivu-modal-close .ivu-icon-ios-close {
  color: #fff;
}
.teamUp1
  .ivu-modal
  .ivu-modal-content
  .ivu-modal-header
  .ivu-modal-header-inner {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  color: #fffdc3;
  height: 50px;
  line-height: 40px;
}
.teamUp1 .ivu-modal-body {
  height: calc(100% - 0px);
  padding: 15px 75px 35px !important;
}
.teamUp2 .ivu-modal-body {
  height: calc(100% - 0px);
  padding: 15px 20px 20px !important;
}
.teamUp3 .ivu-modal-body {
  height: calc(100% - 0px);
  padding: 15px 20px 20px !important;
}
.teamUp2 .ivu-modal .ivu-modal-content .ivu-modal-close {
  right: 10px;
  top: 10px;
  z-index: 1;
}
.teamUp1 .check {
  display: flex;
  justify-content: space-around;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #393d6f;
  .item {
    font-weight: bold;
    color: #00c6ff;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
  }
  .itemD {
    color: #fffdc3;
    position: relative;
  }
  .itemD:before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    height: 3px;
    width: 90px;
    margin-left: -45px;
    border-radius: 1.5px;
    background: #00c6ff;
  }
}
.teamUp1 .form {
  padding-top: 20px;
  .item {
    display: flex;
    justify-content: space-between;
    height: 32px;
    line-height: 32px;
    margin-bottom: 10px;
    .title {
      width: 90px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: #00c6ff;
      line-height: 32px;
    }
    .box {
      width: calc(100% - 95px);
    }
  }
  .itemB {
    margin-top: 20px;
    width: 100%;
    min-height: 120px;
    .itemBT {
      border-top: 1px solid #3486c0;
    }
    .itemBD {
      display: flex;
      height: 40px;
      border-bottom: 1px solid #3486c0;
      text-align: center;
      line-height: 40px;
      color: #00c6ff;
      .T1 {
        width: 100px;
        border-left: 1px solid #3486c0;
      }
      .T2 {
        width: 175px;
        border-left: 1px solid #3486c0;
      }
      .T3 {
        width: 175px;
        border-left: 1px solid #3486c0;
        border-right: 1px solid #3486c0;
      }
    }
  }
}
.teamUp1 .submit {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  .btn {
    width: 83px;
    height: 32px;
    line-height: 32px;
    border-radius: 25px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    margin: 0 10px;
  }
  .btnC {
    border: 1px solid #3be4ff;
    color: #3be4ff;
  }
  .btnO {
    background: #3be4ff;
    color: #000;
  }
}
.teamUp1 {
  .checkCx {
    width: 135px;
    height: 32px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: bold;
    // text-align: center;
    color: #e8e6b3;
    line-height: 32px;
    background: url("../assets/zzbg.png");
    background-size: 100% 100%;
    padding-left: 20px;
  }
  .checkBox {
    height: calc(100% - 42px);
    margin-top: 10px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .checkItem {
      width: 265px;
      min-height: 80px;
      max-height: 250px;
      padding: 12px;
      border: 1px solid #38d1fb;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .item_hea {
        display: flex;
        justify-content: space-between;

        height: 20px;
        line-height: 20px;
        margin-bottom: 8px;
        font-size: 14px;
        .title {
          width: 70px;
          color: #00c6ff;
        }
        .detail {
          width: calc(100% - 70px);
          color: #e8e6b3;
        }
      }
      .jsicon {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 1;
        width: 38px;
        height: 20px;
        background: #fb05053d;
        color: #f98e05a1;
        border-radius: 4px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .checkBox::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .checkBox::-webkit-scrollbar-thumb {
    background-color: #586fb1 !important;
    border-radius: 2px !important;
    border: 1px solid #586fb1;

    /* box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  }
  .checkBox::-webkit-scrollbar-track {
    background-color: rgba(2, 3, 49, 1) !important;
    border-radius: 4px !important;
  }
}
.teamUp3 {
  .uploadBox {
    padding: 12px 100px;
    height: 140px;
    display: flex;
    border-bottom: 1px solid #c0c0c036;
    display: flex;
    justify-content: space-between;
    img {
      width: 200px;
      height: 110px;
    }
    .uploadBox1 {
      width: 180px;
      display: flex;
      justify-content: space-between;
      color: red;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  .checkBoxB {
    height: calc(100% - 182px);
    margin-top: 10px;
    padding: 12px 0 0;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    position: relative;
    .checkItem {
      margin-right: 5px;
    }
  }
}
.teamUp4 {
  .checkBox {
    display: block;
    .checkItem {
      width: 100%;
      border: none;
      color: #fff;
      display: flex;
      flex-direction: row;
      min-height: 0;
      height: 56px;

      position: relative;
      div:first-child {
        width: 120px;
      }
      div:second-child {
        width: 150px;
      }
      div:last-child {
        width: 120px;
        text-align: right;
      }
    }
  }
}

//上面是组队的样式处理
//下面是注册的样式处理
.register1 .item .ivu-input {
  color: #badde0;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid #565867;
}
.register1 .item .ivu-select-selection {
  color: #badde0;
  background: rgba(0, 0, 0, 0) !important;
  border: none;
  border-bottom: 1px solid #565867;
}
.register1 .item .ivu-select-selection:focus {
  color: #44f2ff;
  box-shadow: none;
  border-bottom: 1px solid #44f2ff;
}
.register1 .item .ivu-select-dropdown {
  background: #000;
}
.register1 .item .ivu-select-item {
  color: #fff;
}
.register1 .item .ivu-select-item:hover {
  background: #232225 !important;
}
.item .ivu-input:focus {
  color: #44f2ff;
  box-shadow: none;
  border-bottom: 1px solid #44f2ff;
}

.item .ivu-input::-webkit-input-placeholder {
  color: #f0f0f066;
}
.itemB .ivu-input::-webkit-input-placeholder {
  color: #f0f0f066;
}
.item .ivu-select-single .ivu-select-selection .ivu-select-placeholder {
  color: #f0f0f066 !important;
}
.item .ivu-input:focus::-webkit-input-placeholder {
  color: #44f2ff;
}
.item .ivu-input:focus::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #44f2ff;
}
.item .ivu-input:focus::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #44f2ff;
}
.item .ivu-input:focus::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #44f2ff;
}
.register1 .itemB .ivu-input {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #44f2ff;
  text-align: center;
  color: #badde0;
}

.itemB .ivu-input:focus::-webkit-input-placeholder {
  color: #44f2ff;
  box-shadow: 0px 3px 29px 3px rgba(2, 246, 255, 0.23);
  background: rgba(68, 242, 255, 0.05);
  border-radius: 2px;
}
.itemB .ivu-input:focus::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #44f2ff;
  box-shadow: 0px 3px 29px 3px rgba(2, 246, 255, 0.23);
  background: rgba(68, 242, 255, 0.05);
  border-radius: 2px;
}
.itemB .ivu-input:focus::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #44f2ff;
  box-shadow: 0px 3px 29px 3px rgba(2, 246, 255, 0.23);
  background: rgba(68, 242, 255, 0.05);
  border-radius: 2px;
}
.itemB .ivu-input:focus::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #44f2ff;
  box-shadow: 0px 3px 29px 3px rgba(2, 246, 255, 0.23);
  background: rgba(68, 242, 255, 0.05);
  border-radius: 2px;
}
// 上面是输入框的样式
// 下面的弹框的样式
.register1 .ivu-modal {
  width: 600px !important;
  height: 446px;
  background: url("../assets/login_base.png");
  background-size: 100% 100%;
}
.register1 .ivu-modal .ivu-modal-content {
  width: 100% !important;
  height: 100% !important;
  background: url("../assets/login_base.png");
  background-size: 100% 100%;
}
.register1 .ivu-modal .ivu-modal-content .ivu-modal-header {
  width: 100% !important;
  height: 50px !important;
  padding: 0;
  border-bottom: none;
}
.register1 .ivu-modal .ivu-modal-content .ivu-modal-close {
  right: 22px;
  top: 32px;
  z-index: 1;
}
.register1 .ivu-modal .ivu-modal-content .ivu-modal-close .ivu-icon-ios-close {
  color: #fff;
}
.register1
  .ivu-modal
  .ivu-modal-content
  .ivu-modal-header
  .ivu-modal-header-inner {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  color: #fffdc3;
  height: 50px;
  line-height: 40px;
}
.register1 .ivu-modal-body {
  height: calc(100% - 100px);
}
.register1 .check {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #fffdc3;
  font-size: 18px;
  cursor: pointer;
  .item {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  img {
    width: 20px;
    margin-right: 8px;
  }
  .remark {
    color: #b89999;
    font-size: 14px;
  }
}
.register1 {
  .form {
    width: 300px;
    margin: 20px auto;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .title {
        width: 20px;
        display: flex;
        align-items: center;
        margin-top: 8px;

        img {
          width: 20px;
        }
      }
      .box {
        width: 275px;
      }
    }
    .itemB {
      padding-left: 25px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .title {
        width: 140px;
      }
      .box {
        width: 120px;
        cursor: pointer;
        .btn {
          width: 120px;
          height: 32px;
          line-height: 32px;
          background: #44f2ff;
          border: 1px solid #44f2ff;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 500;
          color: #010b17;
          text-align: center;
        }
        img {
          width: 120px;
          height: 32px;
        }
      }
    }
  }
  .submit {
    margin: 0 auto;
    width: 180px;
    height: 35px;
    background: #3be4ff;
    border-radius: 17px;
    text-align: center;
    line-height: 35px;
    font-size: 18px;
    color: #010b17;
    cursor: pointer;
  }
  .fotter {
    margin-top: 15px;
    text-align: right;
    color: #badde0;
    padding-right: 60px;
    span {
      color: #3be4ff;
      cursor: pointer;
    }
  }
}
// 手机端样式处理
@media screen and (max-width: 500px) {
}
</style>
<style lang="less" scoped>
.container_1200 {
  width: 1200px;
  margin: 0 auto;
}
.loginHome {
  width: 100%;
  //   height: 100vh;
  min-width: 1200px;
  //   min-height: 920px;
  //   padding: 20px 40px 40px;
  box-sizing: border-box;
  .bannerOth {
    width: 100%;
    height: auto;
    min-height: 590px;
    position: relative;
    .bannerImg {
      width: 100%;
    }
    .bannerPhone {
      display: none;
    }
    .header {
      width: 180px;
      height: 60px;
      position: absolute;
      top: 0;
      right: 8%;
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .tx {
        width: 60px;
        height: 60px;
        border: 1px solid #ffffff;
        background: linear-gradient(0deg, #000000 0%, #ffffff 100%);
        border-radius: 50%;
        cursor: pointer;
        margin-bottom: 10px;
      }
      .name {
        width: auto;
        // margin-top: 10px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: var(--coloraction);
        line-height: 30px;
        // background: #f0f0f0;
      }
      .logoOut {
        width: 80px;
        background: #fff;
        border-radius: 5px;
        color: red;
        cursor: pointer;
        position: absolute;
        font-size: 16px;
        top: 100px;
      }
    }
    // .header:hover {
    //   .logoOut {
    //     display: block;
    //   }
    // }
    .suBmitBtn {
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15%;
      color: #ffffff;
      font-size: 30px;
      .btn {
        width: 178px;
        height: 65px;
        background: url("../assets/14.png");
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        span {
          width: 130px;
          height: 40px;
          font-size: 30px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #00eaff;
          line-height: 37px;

          background: linear-gradient(
            0deg,
            #9db8d0 0%,
            #00e3fe 0%,
            #c7ffff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .btn:hover {
        // width: 237px;
        // height: 127px;
        background: url("../assets/13.png");
        background-size: cover;

        // box-shadow: 0px 0px 26px 1px #00D2FF;
        background-position: center;
      }
      .btn:hover span {
        span {
          width: 80px;
          height: 31px;
          font-size: 32px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #00eaff;
          line-height: 37px;
        }
      }
    }
  }
  .bannerIner {
    width: 100%;
    background: url("../assets/bg.png") 50% 0 no-repeat;
    background-size: 100% 100%;
    height: auto;
    min-height: 590px;
    margin-top: -10px;
    .brief {
      padding-top: 55px;
      .headline {
        width: 551px;
        height: 84px;
        background: url("../assets/jianjie.png");
        margin: 0 auto 30px;
        background-size: 100% 100%;
        span {
          width: 125px;
          height: 31px;
          font-size: 32px;
          font-weight: bold;
          color: #00c6ff;
          line-height: 68px;
        }
      }
      .details {
        width: 1195px;
        min-height: 100px;
        background: url("../assets/jianjiebg.png");
        background-size: 100% 100%;
        padding: 60px;
        .title {
          font-size: 20px;
          color: #00ffea;
          line-height: 34px;
          width: 100%;
          text-align: center;
          margin-bottom: 12px;
        }
        .mark {
          text-indent: 40px;
          font-weight: 500;
          color: #00ffea;
          font-size: 16px;
          text-align: justify;
          line-height: 40px;
        }
      }
      .detailBox {
        width: 1195px;
        height: 550px;
        margin-top: 100px;
        padding: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .circle {
          width: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 500px;
          position: relative;
          .step {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: url("../assets/555.png");
            position: absolute;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            img {
              //   width: 54px;
              height: 54px;
            }
            p {
              width: 100px;
              text-align: center;
              height: 40px;
              font-size: 18px;
              font-weight: bold;
              color: #44f2ff;
              line-height: 40px;
            }
            .act {
              width: 188px;
              height: 188px;
              position: absolute;
              top: -28%;
              display: none;
            }
          }
          // .step1 {
          //   left: 0;
          //   top: 35%;
          // }
          // .step2 {
          //   left: 29%;
          //   top: 9px;
          // }
          // .step3 {
          //   left: 71%;
          //   top: 96px;
          // }
          // .step4 {
          //   left: 66%;
          //   top: 314px;
          // }
          // .step5 {
          //   left: 25%;
          //   bottom: 14px;
          // }
          //上面是五个的 下面是四个的
          .step1 {
            left: 0;
            top: 35%;
          }
          .step2 {
            left: 37%;
            top: 9px;
          }
          .step3 {
            left: 74%;
            top: 35%;
          }

          .step5 {
            left: 37%;
            bottom: 0;
          }

          .stepactive {
            .act {
              display: block;
            }
          }
        }
        .remark {
          width: 534px;
          display: flex;
          .remarks {
            width: 100%;
            height: auto;
            min-height: 100px;
            background: url("../assets/jianjiebg.png");
            background-size: 100% 100%;
            padding: 40px 30px;
            .re_title {
              font-size: 20px;
              color: #fffdc3;
              line-height: 34px;
              width: 100%;
              text-align: left;
              margin-bottom: 12px;
            }
            .re_detail {
              font-weight: 500;
              color: #00ffea;
              font-size: 16px;
              text-align: justify;
              line-height: 40px;
            }
          }
        }
      }
      .s_nBox {
        width: 1195px;
        min-height: 350px;
        margin-top: 36px;
        padding: 40px;
        .s_nItem {
          display: flex;
          justify-content: space-between;
          padding: 0 41px;
          .It {
            width: 235px;
            text-align: center;
            .title {
              height: 32px;
              line-height: 32px;
              font-size: 22px;
              color: #fffdc3;
            }
            .mark {
              min-height: 40px;
              line-height: 20px;
              font-size: 14px;
              color: #fffdc3;
            }
          }
        }
      }

      .s_detailBox {
        width: 1195px;
        min-height: 450px;
        padding: 40px;
        .s_item {
          width: 100%;
          display: flex;
          margin-bottom: 35px;
          .s_ttile {
            width: 169px;
            height: 47px;
            background: url("../assets/item.png");
            font-size: 18px;
            font-weight: 800;
            color: #fffdc3;
            line-height: 43px;
          }
          .s_mark {
            width: 867px;
            margin-left: 10px;
            font-weight: 500;
            color: #bdfdff;
            line-height: 40px;
            font-size: 18px;
            text-align: left;
          }
        }
      }
      .jx_tab {
        display: flex;
        color: #fff;
        font-size: 16px;
        justify-content: center;

        div {
          width: 124px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          margin: 0 20px;
          cursor: pointer;
          background: url("../assets/JXB.png");
        }
        div:hover {
          background: url("../assets/JXA.png");
        }
        .active_class {
          background: url("../assets/JXA.png");
        }
      }
      .j_detailBox {
        width: 1195px;
        height: 325px;
        margin-top: 50px;
        padding: 61px;
        background: url("../assets/jianjiebg.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .j_item {
          width: 300px;
          height: 214px;
          margin-bottom: 10px;
          cursor: pointer;
          .j_img {
            width: 149px;
            margin-bottom: 10px;
          }
          p {
            color: #fffdc3;
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: 600;
            color: #fffdc3;
            line-height: 26px;
          }
          .o_img {
            margin-top: -40px;
            width: 0px;
          }
        }
        .j_itL {
          margin-left: 160px;
        }
        .j_itR {
          margin-right: 160px;
        }
        .j_item:hover .o_img {
          width: 147px;
        }
      }
      .sx_detailBox {
        width: 1195px;
        min-height: 550px;
        padding: 61px;
        .sx_title {
          width: 159px;
          height: 44px;
          font-size: 22px;
          font-weight: bold;
          color: #fffdc3;
          line-height: 44px;
          text-align: left;
          padding-left: 20px;
          background: url("../assets/zzbg.png");
          background-size: 100% 100%;
          margin-bottom: 25px;
        }
        .sx_box {
          display: flex;
          flex-wrap: wrap;
          .b_item {
            width: auto;
            padding: 0 30px;
            height: 60px;
            line-height: 60px;
            font-weight: 400;
            color: #ffffff;
            background: url("../assets/sxzz_base.png");
            background-size: 100% 100%;
            margin-bottom: 30px;
            margin-right: 70px;
            font-size: 18px;
          }
        }
      }
      .lx_detailBox {
        width: 1195px;
        min-height: 300px;
        padding: 61px;
        img {
          width: 55px;
          height: 45px;
        }
        div {
          display: flex;
          flex-wrap: wrap;
          height: 45px;
          line-height: 45px;
          margin-bottom: 10px;
          color: #fff;
          font-size: 20px;
        }
        .lx_title {
          margin-right: 10px;
        }
      }
    }
    .fotter {
      height: 160px;
      line-height: 135px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      // padding-bottom: 40px;
      justify-content: space-around;
      width: 1200px;
      margin: 0 auto;
      padding: 0 300px;
    }
  }
}
@media screen and (min-width: 500px) {
  .detailBox_phone,
  .s_nBox_img_phone {
    display: none;
  }
}

// 手机端样式处理
@media screen and (max-width: 500px) {
  .container_1200 {
    width: 100%;
    margin: 0 auto;
  }
  .loginHome {
    width: 100%;
    max-width: 500px;
    min-width: 0px;
    .bannerOth {
      min-height: 0px;

      .bannerImg {
        display: none;
      }
      .bannerPhone {
        display: block;
        width: 100%;
      }
      .header {
        width: 135px;
        height: 50px;
        // right: 3%;
        .tx {
          width: 50px;
          height: 50px;
        }
        .name {
          font-size: 14px;
          line-height: 10px;
          width: 100%;
          // margin-top: 10px;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: var(--coloraction);
          line-height: 30px;
        }
      }
      .suBmitBtn {
        bottom: 15%;
        height: 50px;
        font-size: 14px;
        .btn {
          width: 90px;
          height: 50px;
          span {
            width: 70px;
            height: 40px;
            font-size: 14px;
            line-height: 40px;
          }
        }
        .btn:hover {
          background: url("../assets/14.png");
          background-size: cover;
          background-position: center;
        }
      }
    }
    .bannerIner {
      margin-top: 0px;
      .brief {
        padding-top: 10px;
        .phoneHeadLine {
          display: none;
        }
        .headline {
          width: 200px;
          height: 30px;
          background: url("../assets/phone/jianjie.png");
          background-size: 100% 100%;
          margin: 0 auto 10px;
          span {
            font-size: 15px;
            line-height: 23px;
          }
        }
        .details {
          width: 99%;
          background: none;
          padding: 12px 24px;
          .title {
            font-size: 14px;
            line-height: 24px;
          }
          .mark {
            text-indent: 32px;
            font-size: 12px;
            line-height: 24px;
            color: #ffffff;
          }
        }
        //报名流程
        .detailBox,
        .s_nBox_img {
          display: none;
        }
        .detailBox_phone {
          padding-top: 12px;
          .bm_item {
            width: 100%;
            display: flex;
            min-height: 30px;
            line-height: 20px;
            margin: 0 auto;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 0 24px;
            img {
              width: 80%;
            }
            .box {
              //手机端的报名
              // width: calc(100% - 70px);
              width: 100%;
              text-align: left;
              .title {
                font-size: 14px;
                line-height: 18px;
                color: #fffdc3;
                margin-bottom: 5px;
                text-align: center;
              }
              .mark {
                font-size: 12px;
                line-height: 20px;
                color: #bdfdff;
                text-indent: 24px;
              }
            }
          }
        }
        // 赛程安排
        .s_nBox {
          width: 99%;
          padding: 12px;
          min-height: 0;
          margin-top: 12px;
          .s_nBox_img_phone {
            width: 80%;
          }
          .s_nItem {
            padding: 0 15px;
            .It {
              width: 100px;
              .title {
                font-size: 12px;
              }
              .mark {
                font-size: 9px;
              }
            }
          }
        }
        .s_detailBox {
          width: 100%;
          padding: 12px;
          .s_item {
            display: block;
            margin-bottom: 20px;
            .s_ttile {
              width: 100px;
              height: 29.5px;
              font-size: 12px;
              line-height: 28px;
              background-size: 100%;
              margin: 0 auto 9px;
            }
            .s_mark {
              width: 100%;
              margin-left: 0;
              line-height: 26px;
              font-size: 12px;
              text-indent: 28px;
            }
          }
        }
        //奖项设置
        .jx_tab {
          margin-top: 20px;
          div {
            zoom: 0.7;
          }
        }
        .j_detailBox {
          width: 100%;
          background: none;
          margin-top: 12px;
          padding: 12px;
          height: 140px !important;
          align-items: center;
          .j_item {
            width: 95px;
            height: 88px;
            margin-bottom: 10px;
            cursor: pointer;
            .j_img {
              width: auto;
              height: 60px;
              margin-bottom: 10px;
            }
            p {
              line-height: 26px;
              font-size: 12px;
            }
            .o_img {
              display: none;
            }
          }
          .j_itL {
            margin-left: 50px;
          }
          .j_itR {
            margin-right: 50px;
          }
        }
        //赛项组织
        .sx_detailBox {
          width: 100%;
          background: none;
          // margin-top: 12px;
          padding: 12px;
          min-height: 510px;
          .sx_title {
            width: 119px;
            height: 34px;
            line-height: 34px;
            font-size: 16px;
            margin: 15px auto;
          }
          .sx_box {
            .b_item {
              width: 100%;
              height: 40px;
              line-height: 40px;
              font-weight: 400;
              color: #ffffff;
              background-size: 100% 100%;
              margin-bottom: 10px;
              font-size: 14px;
              margin-right: 0px;
            }
          }
        }
        .lx_detailBox {
          width: 100%;
          padding: 12px;
          min-height: 150px;
          div {
            height: auto;
            line-height: 30px;
            font-size: 13px;
            min-height: 30px;
            img {
              width: 33px;
              height: 27px;
            }
            .lx_title {
              width: 100%;
            }
            .lx_mark {
              text-indent: 35px;
            }
          }
        }
        .lx_detailImg {
          width: 90%;
        }
      }
      .fotter {
        width: 100%;
        word-wrap: break-word;
        line-height: 50px;
        height: 105px;
        padding: 0 30px;
        display: block;
      }
    }
  }
}
</style>
