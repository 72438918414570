<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelf">
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll">
          <div style="color: #000001" v-if="zuupduiList.length == 0">
            暂时没有团队...
          </div>
          <Spin fix v-show="spinFlag">
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            ></Icon>
            <div>Loading</div>
          </Spin>
          
          <div class="uploadBox" v-if="zuupduiList?.length > 0">
            <div
              @click="newAdd"
              style="
                color: #515a6e;
                display: flex;

                cursor: pointer;
                width: 116px;
                height: 40px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  height: 32px;
                  width: 100%;
                  border: 1px solid #dcdee2;
                  border-radius: 5px;
                  line-height: 32px;
                  text-align: center;
                "
              >
                <Icon
                  type="ios-cloud-download-outline"
                  style="color: #515a6e; font-weight: 600"
                />下载报名表
              </div>
            </div>

            <div class="uploadBox1">
              <Upload :before-upload="handleUpload" action="/">
                <Button style="color: color: #515a6e;"
                  ><Icon
                    type="ios-cloud-upload-outline"
                    style="font-weight: 600;color: #515a6e;"
                  />上传报名表</Button
                >
              </Upload>
            </div>

            
            <Image
              stylye="width: 100%"
              v-show="zuupduiList[0]?.contact_image_abs_path != ''"
              :src="showImg"
              :preview-list="urlList"
              fit="cover"
              preview
              :initial-index="index"
            />
            <p
              style="position: absolute; color: red; bottom: 0; font-size: 10px"
            >
              支持上传JPG、JPEG、PNG类型图片，且图片大小不能大于5M
            </p>
          </div>
          <div
            class="checkItem"
            v-for="(item, index) in zuupduiList"
            :key="index"
          >
            <div class="item_hea">
              <div class="title">团队名称</div>
              <div class="detail">{{ item.team_name }}</div>
            </div>
            <div class="item_hea">
              <div class="title">团队类型</div>
              <div class="detail">{{ item.team_type }}</div>
            </div>
            <div class="item_hea" v-if="item.teacher1?.name">
              <div class="title">指导教师1</div>
              <div class="detail">
                {{ item.teacher1?.name }}-{{ item.teacher1?.phone }}
              </div>
            </div>
            <div class="item_hea" v-if="item.teacher2?.name">
              <div class="title">指导教师2</div>
              <div class="detail">
                {{ item.teacher2?.name }}-{{ item.teacher2?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队长</div>
              <div class="detail">
                {{ item.leader?.name }}-{{ item.leader?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员1</div>
              <div class="detail">
                {{ item.member1?.name }}-{{ item.member1?.phone }}
              </div>
            </div>
            <div class="item_hea">
              <div class="title">队员2</div>
              <div class="detail">
                {{ item.member2?.name }}-{{ item.member2?.phone }}
              </div>
            </div>
          </div>
        </div>

        <div class="btnBox">
          <div class="btn cacel" @click="proxy.$router.push('/')">返回</div>
        </div>
        <down-file ref="downFileNull"></down-file>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import downFile from "./downFile";
import {
  getSchoolContactsUser,
  getSchoolAllTeams,
  uploadSchoolContactImage,
} from "@/api/ViewScreen/index";
let nowheader = ref("学校团队");
const CONFIG = window.CONFIG;
let zuupduiList = ref([]);
let showImg = ref("");
const urlList = ref([]);
const downFileNull = ref(null);
const spinFlag = ref(false);
const newAdd = (val, obj) => {
  downFileNull.value.showUp();
};
// 查询学校联络员
const getLLY = () => {
  spinFlag.value = true;
  zuupduiList.value = [];
  getSchoolContactsUser({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        getSchoolAllTeams({ token: storage.session.get("sToken") }).then(
          (res) => {
            spinFlag.value = false;
            if (res.code == 1) {
              zuupduiList.value = res.data;
              showImg.value =
                process.env.VUE_APP_API_URL +
                res.data[0].contact_image_abs_path;
              urlList.value = [showImg.value];
            } else {
              proxy.$Message.error(res.msg);
            }
          }
        );
      } else {
        return;
      }
    }
  );
};
let fileD = ref(); //选中图片文件
//上传头像
const handleUpload = (file) => {
  if (
    file.type == "image/jpeg" ||
    file.type == "image/jpg" ||
    file.type == "image/png"
  ) {
    console.log(file)
    if (file.size > 5 * 1024 * 1024) {
      proxy.$Message.warning("图片大小不能超过5M");
      return;
    }

    fileD.value = file;
    spinFlag.value = true;
    uploadSchoolContactImage({
      token: storage.session.get("sToken"),
      image: fileD.value,
    }).then((res) => {
      spinFlag.value = false;
      if (res.code == 1) {
        proxy.$Message.success(res.msg);
        getSchoolAllTeams({ token: storage.session.get("sToken") }).then(
          (res1) => {
            if (res1.code == 1) {
              zuupduiList.value = res1.data;
              showImg.value =
                process.env.VUE_APP_API_URL +
                res1.data[0].contact_image_abs_path;
              urlList.value = [showImg.value];
            } else {
              proxy.$Message.error(res1.msg);
            }
          }
        );
      } else {
        proxy.$Message.error(res.msg);
      }
    });
  } else {
    proxy.$Message.warning("仅支持上传jpg/jpeg/png类型的图片");
  }
};
onMounted(() => {
  getLLY();
});
</script>
<style>
.mySelf .ivu-select-placeholder {
  text-align: left !important;
}
.mySelf .ivu-select-selected-value {
  text-align: left !important;
}

.mySelf .box .ivu-select {
  position: relative;
}
.mySelf .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}
.mySelf {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
      }
    }
    .detailBox {
      padding: 30px 20px 20px;
      border: 1px solid #eeeef0;
      flex: 1;
      position: relative;
      .checkItem {
        width: 100%;
        min-height: 80px;
        max-height: 300px;
        padding: 12px;
        border-bottom: 1px solid #eeeef0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .item_hea {
          display: flex;
          justify-content: space-between;

          height: 30px;
          line-height: 30px;
          margin-bottom: 10px;
          font-size: 16px;
          .title {
            width: 80px;
            color: #183139c9;
            font-weight: 600;
          }
          .detail {
            width: calc(100% - 90px);
            text-align: left;
            color: #1c0202c2;
          }
        }
        .jsicon {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          z-index: 1;
          width: 38px;
          height: 20px;
          background: #fb05053d;
          color: #f98e05a1;
          border-radius: 4px;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
        }
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      height: 50px;
      align-items: center;
      .btn {
        cursor: pointer;
        width: auto;
        padding: 0 18px;
        margin: 0 12px;
        line-height: 28px;
        height: 28px;
        text-align: center;
      }
      .cacel {
        border: 1px solid #c0c0c0;
      }
      .ok {
        border: 1px solid #0080ff;
        color: #fff;
        background: #0080ff;
      }
      .ok2 {
        border: 1px solid #0080ff;
        padding: 0 8px;
        color: #0080ff;
      }
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    background: none;
  }
  .mySelf {
    display: block !important;
    width: 100%;
    padding: 16px;
    .right {
      width: 100%;
      height: 100%;
      .detailBox {
        .itemMyself {
          display: block;
          .title {
            width: 80px !important;
            text-align: left;
            color: #130b0b9c;
          }
          .box {
            width: 100%;
          }
        }
        .uploadBox {
          display: flex;
          // flex-direction: column;
          // align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          position: relative;
          padding-bottom: 20px;
          .uploadBox1 {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
