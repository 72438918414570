<template>
  <div class="downFile" ref="capture" v-show="isShow">
    <div class="titleN">{{ CONFIG.fileTitle }}</div>
    <div class="schoolName">
      学校：{{
        schoolName
      }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 联络员：{{
        teacher
      }}
    </div>
    <!-- <div class="teacherName">学校联系人：{{ teacher }}</div> -->
    <div class="table1" v-show="teacherList.length > 0">
      <div class="title" style="font-weight: 600">
        <div class="name">团队昵称（教师队）</div>
        <div class="stuName">姓名</div>
        <div class="idNumber">身份证号</div>
        <div class="phoneNum">手机号</div>
      </div>
      <div class="list title" v-for="item in teacherList">
        <div class="name">
          {{ item.team_nikename }}
        </div>
        <div style="border: none">
          <div class="stuList">
            <div class="stuName">
              {{ item.leader?.name }}
            </div>

            <div class="idNumber">{{ item.leader?.idcard }}</div>
            <div class="phoneNum">{{ item.leader?.phone }}</div>
          </div>
          <div class="stuList">
            <div class="stuName">{{ item.member1?.name }}</div>

            <div class="idNumber">{{ item.member1?.idcard }}</div>
            <div class="phoneNum">{{ item.member1?.phone }}</div>
          </div>
          <div class="stuList">
            <div class="stuName">{{ item.member2?.name }}</div>

            <div class="idNumber">{{ item.member2?.idcard }}</div>
            <div class="phoneNum">{{ item.member2?.phone }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="table" v-show="stundentList.length > 0">
      <div class="title" style="font-weight: 600">
        <div class="name">团队昵称(学生队)</div>
        <div class="teacher">指导教师1</div>
        <div class="teacher">指导教师2</div>
        <div class="stuName">姓名</div>
        <div class="idNumber">身份证号</div>
        <div class="stuNum">学号</div>
        <div class="phoneNum">手机号</div>
      </div>
      <div class="list title" v-for="item in stundentList">
        <div class="name">
          {{ item.team_nikename }}
        </div>
        <div class="teacher">{{ item.teacher1?.name }}</div>
        <div class="teacher">{{ item.teacher2?.name }}</div>
        <div style="border: none">
          <div class="stuList">
            <div class="stuName">{{ item.leader?.name }}</div>
            <div class="idNumber">{{ item.leader?.idcard }}</div>
            <div class="stuNum">{{ item.leader?.student_number }}</div>
            <div class="phoneNum">{{ item.leader?.phone }}</div>
          </div>
          <div class="stuList">
            <div class="stuName">{{ item.member1?.name }}</div>
            <div class="idNumber">{{ item.member1?.idcard }}</div>
            <div class="stuNum">{{ item.member1?.student_number }}</div>
            <div class="phoneNum">{{ item.member1?.phone }}</div>
          </div>
          <div class="stuList">
            <div class="stuName">{{ item.member2?.name }}</div>
            <div class="idNumber">{{ item.member2?.idcard }}</div>
            <div class="stuNum">{{ item.member2?.student_number }}</div>
            <div class="phoneNum">{{ item.member2?.phone }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance } from "vue";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { storage } from "llm-web-tool";
import { getSchoolAllTeams, getSchoolContactsUser } from "@/api/ViewScreen";
const { proxy } = getCurrentInstance();
const isShow = ref(true);
const schoolName = ref("");
const teacher = ref("");
const capture = ref(null);
const teacherList = ref([]);
const stundentList = ref([]);
const order = ["本科学生组", "高职高专学生组", "中职学生组"];
const getSchoolAllTeamsL = () => {
  teacherList.value = [];
  stundentList.value = [];
  getSchoolContactsUser({ token: storage.session.get("sToken") }).then(
    (res) => {
      if (res.code == 1) {
        schoolName.value = res.data.school.name;
        teacher.value = res.data.name + "-" + res.data.phone;
        getSchoolAllTeams({ token: storage.session.get("sToken") }).then(
          (res1) => {
            if (res1.code == 1) {
              console.log(res1.data);
              res1.data.forEach((item) => {
                if (item.team_type == "教师组") {
                  teacherList.value.push(item);
                } else {
                  stundentList.value.push(item);
                }
              });
              stundentList.value.sort((a, b) => {
                return order.indexOf(a.team_type) - order.indexOf(b.team_type);
              });
              console.log(teacherList, stundentList, 633);
              proxy.$nextTick(() => {
                if (!capture.value) {
                  return;
                } else {
                  html2canvas(capture.value).then((canvas) => {
                    // 将 Canvas 转换为 Blob，然后保存

                    canvas.toBlob((blob) => {
                      saveAs(blob, `${schoolName.value}报名信息表.png`);
                    }, "image/png");
                  });
                }
              });
            }
          }
        );
      }
    }
  );
};
const showUp = (name) => {
  isShow.value = true;
  getSchoolAllTeamsL();
};
const CONFIG = window.CONFIG;

defineExpose({
  showUp,
});
</script>
<style lang="less" scoped>
.downFile {
  position: fixed;
  bottom: -400px;
  z-index: -9999;
  width: 1200px;
  height: auto;
  padding: 50px 30px;
  .titleN {
    font-size: 18px;
    text-align: center;
    height: 40px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 40px;
  }
}

.schoolName,
.teacherName {
  font-size: 16px;
  text-align: center;
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
}
.table {
  border-top: 1px solid #f0f0f0;

  .title {
    border-left: 1px solid #f0f0f0;
    display: flex;

    div {
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      min-height: 30px;
      line-height: 30px;
    }
    .stuList {
      border-right: none;
    }
    .name {
      width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .teacher {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .stuName {
      width: 150px;
    }
    .idNumber {
      width: 200px;
    }
    .stuNum {
      width: 200px;
    }
    .phoneNum {
      width: 120px;
    }
    .stuList {
      display: flex;
    }
  }
}
.table1 {
  width: 741px;
  margin: 0 auto 20px;
  border-top: 1px solid #f0f0f0;

  .title {
    border-left: 1px solid #f0f0f0;
    display: flex;

    div {
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      min-height: 30px;
      line-height: 30px;
    }
    .stuList {
      border-right: none;
    }
    .name {
      width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .stuName {
      width: 150px;
    }
    .idNumber {
      width: 200px;
    }

    .phoneNum {
      width: 120px;
    }
    .stuList {
      display: flex;
    }
  }
}
</style>
