import {
  XHR,
  BaseURL
} from '../common/index_java.js'

// 请求拦截器
const beforeSend = (options) => {
  return options;
}

// 响应拦截器
const befroeResponse = (response) => {
  return response;
}

// 错误处理器
const onRequestError = (e) => {
  return e;
}

// 请求执行方法
const send = (options) => {
  return new Promise((resolve, reject) => {
    XHR(beforeSend(options)).then(r => {
      resolve(befroeResponse(r));
    }).catch(e => {
      reject(onRequestError(e));
    })
  })
}


// 获取图形验证码
export const getCaptcha = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/login/getCaptcha";
  options.method = 'get'
  return `${BaseURL}${options.url}?&scene=${data.scene}&sign=${data.sign}`

};
// 获取注册时手机验证码
export const sendRegisterCode = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/login/sendRegisterCode";
  return send(options);
};
// 用户注册
export const doRegister = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/login/doRegister";
  return send(options);
};
// 用户登录
export const doLogin = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/login/doLogin";
  return send(options);
};

// 忘记密码获取手机验证码
export const sendChangePwdCode = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/login/sendChangePwdCode";
  return send(options);
};

// 忘记密码修改
export const doChangePwd = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/login/doChangePwd";
  return send(options);
};
// 获取个人信息
export const getMyInfo = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/User/getMyInfo";
  return send(options);
};
// 判断个人信息是否完善
export const isMyInfoPerfect = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/User/isMyInfoPerfect";
  return send(options);
};
// 上传头像
export const uploadHeadImg = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/User/uploadHeadImg";
  return send(options);
};

// 修改个人信息
export const setMyInfo = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/User/setMyInfo";
  options.param_typearr = true
  options.canshu = true
  return send(options);
};
//修改密码
export const changePassword = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/User/changePassword";
  return send(options);
};
//获取学校信息列表
export const getSchoolList = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/User/getSchoolList";
  return send(options);
};
//申请成为学校联系人
export const applyToSchoolContacts = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/applyToSchoolContacts";
  return send(options);
};
//查看本校联系人
export const getSchoolContactsUser = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/getSchoolContactsUser";
  return send(options);
};

//组建教师队
export const createTeacherTeam = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/createTeacherTeam";
  return send(options);
};
//组建学生队
export const createStudentTeam = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/createStudentTeam";
  return send(options);
};
//查询组队信息
export const getMyCreatTeam = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/getTeacherAllTeams";
  return send(options);
};
//解散团队
export const delMyTeam = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/delMyTeam";
  return send(options);
};
//教师查询本校报错学生及线上赛成绩
export const checkMySchoolStudent = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/getMySchoolStudents";
  return send(options);
};
//教师查询本校学生可组队的团队类型
export const getTeamType = (data, options = {}) => {
  options.data = data;
  options.url = "/indexapi/teacher/getTeamType";
  return send(options);
};

//联络员查看学校所有团队
export const getSchoolAllTeams = (data, options = {}) => {
  options.data = data;
  options.url = "indexapi/teacher/getSchoolAllTeams";
  return send(options);
};
//上传图片
export const uploadSchoolContactImage = (data, options = {}) => {
  options.data = data;
  options.url = "indexapi/teacher/uploadSchoolContactImage";
  return send(options);
};
//学生查看加入的团队
export const getMyTeam = (data, options = {}) => {
  options.data = data;
  options.url = "indexapi/user/getMyTeam";
  return send(options);
};
