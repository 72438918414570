<template>
  <div style="height: 100vh" class="wwwscroll aaa">
    <div class="mySelfLogin">
      <div class="right">
        <div class="R_header">
          <p>{{ nowheader }}</p>
        </div>
        <div class="detailBox wwwscroll">
          <!-- <div class="check"> -->
            <!-- <div class="item" @click="rClick(2)">
              <img
                v-if="registerObj.type == 1"
                src="@/assets/checkno.png"
                alt=""
              />
              <img
                v-if="registerObj.type == 2"
                src="@/assets/checkin.png"
                alt=""
              />
              我是教师
            </div>
            <div class="item" @click="rClick(1)">
              <img
                v-if="registerObj.type == 2"
                src="@/assets/checkno.png"
                alt=""
              />
              <img
                v-if="registerObj.type == 1"
                src="@/assets/checkin.png"
                alt=""
              />
              我是学生
            </div> -->
            <!-- <div class="remark">提示：身份注册后不可更改</div> -->
          <!-- </div> -->
          <div class="form">
            <div class="item">
              <div class="title"><img src="@/assets/phone.png" alt="" /></div>
              <div class="box">
                <Input
                  style="width: 100%"
                  v-model="registerObj.phone"
                  placeholder="请输入手机号"
                  @on-blur="blurPhone"
                />
              </div>
            </div>
            <div class="item">
              <div class="title">
                <img src="@/assets/password.png" alt="" />
              </div>
              <div class="box">
                <Input
                  style="width: 100%"
                  v-model="registerObj.password"
                  placeholder="请设置密码（最少6位）"
                />
              </div>
            </div>
            <div class="item">
              <div class="title">
                <img src="@/assets/zhao.png" alt="" />
              </div>
              <div class="box">
                <!-- <Input
                  style="width: 100%"
                  v-model="registerObj.password"
                  placeholder="请设置密码（最少6位）"
                /> -->
                <Select placeholder="请选择身份" v-model="registerObj.user_type" style="width: 100%">
                <Option value="教师">教师</Option>
                <Option value="本科学生">本科学生</Option>
                <Option value="高职高专学生">高职高专学生</Option>
                <Option value="中职学生">中职学生</Option>
              </Select>
              </div>
            </div>
            <div class="itemB">
              <div class="title">
                <Input
                  style="width: 100%"
                  v-model="registerObj.yzm"
                  placeholder="图形验证码"
                />
              </div>
              <div class="box" @click="getLoginCaptcha">
                <img :src="txyzm" alt="" />
              </div>
            </div>
            <div class="itemB">
              <div class="title">
                <Input
                  style="width: 100%"
                  v-model="registerObj.yzm2"
                  placeholder="验证码"
                />
              </div>
              <div class="box" @click="getPhoneNum">
                <div class="btn">{{ yzmNewTitle }}</div>
              </div>
            </div>
            <div class="submit" @click="registerOk">立即注册</div>
            <div class="fotter">
              已有账号?<span @click="toLogin">请登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { storage } from "llm-web-tool";
const { proxy } = getCurrentInstance();
import { debounce } from "@/utils/AntiShake";
import {
  getCaptcha,
  doRegister,
  sendRegisterCode,
} from "@/api/ViewScreen/index";
let nowheader = ref("注册");
const CONFIG = window.CONFIG;
//手机号正则
const getRegPhone = (phone) => {
  var regExp = /^1[3456789]\d{9}$/;
  if (regExp.test(phone)) {
    return true;
  } else {
    return false;
  }
};
const blurPhone = () => {
  if (registerObj.phone) {
    if (getRegPhone(registerObj.phone)) {
      return;
    } else {
      proxy.$Message.warning("请输入正确的手机号码");
    }
  } else {
    proxy.$Message.warning("请输入手机号码");
  }
};
//注册变量
//注册倒计时
let yzmNewTitle = ref("获取短信验证码");
let yzmNewFlag = ref(false);
let timeNew = ref(60);
let timer = "";
const setIntervalfn = () => {
  if (yzmNewFlag.value) {
    return;
  } else {
    yzmNewFlag.value = true;
    timer = setInterval(() => {
      if (yzmNewFlag.value == true) {
        // console.log("触发");
        yzmNewTitle.value = timeNew.value + " s";
        timeNew.value--;
        callback(timeNew);
      }
    }, 1000);
    const callback = (timeNew) => {
      // console.log(timeNew.value);
      if (timeNew.value < 0) {
        timeNew.value = 60;
        yzmNewFlag.value = false;
        yzmNewTitle.value = "获取验证码";
        console.log(yzmNewFlag.value);
        clearInterval(timer);
      }
    };
  }
};
let registerObj = reactive({
  user_type: '', //0是教师 1是学生
  phone: "", //手机号
  password: "", //密码
  yzm: "", //图形验证码
  yzm2: "", //短信验证码
  CAPTCHA: "", //数字验证码标识
});
const registerInit = () => {
  // eslint-disable-next-line no-const-assign
  registerObj = reactive({
    user_type: '', //0是教师 1是学生
    phone: "", //手机号
    password: "", //密码
    yzm: "", //图形验证码
    yzm2: "", //短信验证码
    CAPTCHA: "", //数字验证码标识
  });
  timeNew.value = 60;
  yzmNewFlag.value = false;
  yzmNewTitle.value = "获取验证码";
  console.log(yzmNewFlag.value);
  clearInterval(timer);
};

const toLogin = () => {
  proxy.$router.push("./loginPhone");
};
const rClick = (val) => {
  registerObj.type = val;
};

let CAPTCHA_SIGN = ref(""); //图片验证码随机签名
let txyzm = ref("");
//获取验证码
const getLoginCaptcha = debounce(() => {
  registerObj.yzm =''
  CAPTCHA_SIGN.value = "";
  CAPTCHA_SIGN.value = Math.ceil(Math.random() * 10000000000 + 1);
  // 请求验证码后放到这里
  txyzm.value = getCaptcha({ scene: "register", sign: CAPTCHA_SIGN.value });
}, 100);

//注册 获取手机验证码
const getPhoneNum = debounce(() => {
  if (yzmNewFlag.value) {
    return;
  }
  if (registerObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (registerObj.user_type == "") {
    proxy.$Message.warning("请选择用户身份");
    return;
  }
  if (registerObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  sendRegisterCode({
    phone: registerObj.phone,
    user_type: registerObj.user_type,
    sign: CAPTCHA_SIGN.value,
    captcha: registerObj.yzm,
  }).then((res) => {
    console.log(res);
    if (res.code == 1) {
      setIntervalfn();
    } else {
      registerObj.yzm = ""
      getLoginCaptcha()
      proxy.$Message.error(res.msg);
    }
  });
}, 1000);
//注册执行函数
const registerOk = debounce(() => {
  if (registerObj.phone == "") {
    proxy.$Message.warning("请输入手机号");
    return;
  }
  if (registerObj.password == "" || registerObj.password.length < 6) {
    proxy.$Message.warning("请设置密码且密码不能少于6位");
    return;
  }
  if (registerObj.user_type == "") {
    proxy.$Message.warning("请选择用户身份");
    return;
  }
  if (registerObj.yzm == "") {
    proxy.$Message.warning("请输入图形验证码");
    return;
  }
  if (registerObj.yzm2 == "") {
    proxy.$Message.warning("请输入手机验证码");
    return;
  }
  doRegister({
    phone: registerObj.phone,
    code: registerObj.yzm2,
    password: registerObj.password,
    user_type: registerObj.user_type,
  }).then((res) => {
    if (res.code == 1) {
      
      proxy.$router.push("./loginPhone");
      proxy.$Message.success(res.msg);
    } else {
      proxy.$Message.error(res.msg);
    }
  });
}, 500);

onMounted(() => {
  registerInit();
  getLoginCaptcha();
});
</script>
<style>

.mySelfLogin .ivu-input {
  height: 40px !important;
  
}
.mySelfLogin .item .ivu-input {
  color: #badde0;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid #565867;
}
.mySelfLogin .itemB .ivu-input {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #44f2ff;
  text-align: center;
  color: #badde0;
}
.mySelfLogin .item .ivu-select-selection {
  color: #badde0;
  background: rgba(0, 0, 0, 0) !important;
  border: none;
  border-bottom: 1px solid #565867;
}
.mySelfLogin .item .ivu-select-selection:focus {
  color: #44f2ff;
  box-shadow: none;
  border-bottom: 1px solid #44f2ff;
}
.mySelfLogin .item .ivu-select-dropdown {
  background: #000;
}
.mySelfLogin .item .ivu-select-item {
  color: #fff;
  
}
.mySelfLogin .item .ivu-select-item:hover {
  background: #232225 !important;
}
.mySelfLogin .ivu-select-selected-value {
  text-align: left !important;
}

.mySelfLogin .box .ivu-select {
  position: relative;
  text-align: left!important;
}
.mySelfLogin .ivu-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style lang="less" scoped>
.aaa {
  background: url("../assets/bg.png") 50% 0 no-repeat;
  background-size: 100% 100%;
}
.mySelfLogin {
  width: 960px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 20px 0;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0);
  .right {
    width: 650px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .R_header {
      padding: 0 5px;
      border-bottom: 1px solid #ededed;
      text-align: left;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 600;

      p {
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 3px;
        border-bottom: 1px solid #0080ff;
        color: #fff;
      }
    }
    .detailBox {
      padding: 30px 20px 20px;
      flex: 1;
      position: relative;
    }
  }
}

// 手机端样式
@media screen and (max-width: 500px) {
  .aaa {
    // background: none;
  }
  .mySelfLogin {
    display: block !important;
    width: 100%;
    padding: 16px;
    .right {
      width: 100%;
      height: 100%;
      .detailBox {
        .form {
          width: 310px;
          margin: 30px auto;
          .item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .title {
              width: 20px;
              display: flex;
              align-items: center;

              img {
                width: 20px;
              }
            }
            .box {
              width: 275px;
            }
          }
          .itemB {
            padding-left: 35px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .title {
              width: 140px;
            }
            .box {
              width: 120px;
              cursor: pointer;
              .btn {
                width: 120px;
                height: 40px;
                line-height: 40px;
                background: #44f2ff;
                border: 1px solid #44f2ff;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 500;
                color: #010b17;
                text-align: center;
              }
              img {
                width: 120px;
                height: 40px;
              }
            }
          }
        }
        .submit {
          margin: 0 auto;
          width: 180px;
          height: 35px;
          background: #0556ab;
          border-radius: 17px;
          text-align: center;
          line-height: 35px;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
        }
        .fotter {
          margin-top: 15px;
          text-align: right;
          color: #badde0;
          // padding-right: 60px;
          span {
            color: #3be4ff;
            cursor: pointer;
          }
        }
        .check {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-around;
          //   color: #fffdc3;
          font-size: 18px;
          cursor: pointer;
          .item {
            display: flex;
            align-items: center;
            margin-right: 20px;
            color: #fff;
          }
          img {
            width: 20px;
            margin-right: 8px;
          }
          .remark {
            color: #b89999;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
